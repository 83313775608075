import Typography from '@mui/material/Typography';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  TextField,
  Checkbox,
  Divider,
  Link,
  Box,
  Button,
} from '@mui/material';
import { useState } from 'react';
import { scroller } from 'react-scroll';

export function Step6({
  handleNext,
  handleChange,
  handleBack,
  values: { simultaneousTasks },
  formErrors,
}) {
  const [termsCheckbox, setTermsCheckbox] = useState(false);
  const handleTermsCheckbox = (e, c) => {
    setTermsCheckbox(!termsCheckbox);
  };

  const [privacyCheckbox, setPrivacyCheckbox] = useState(false);
  const handlePrivacyCheckbox = (e, c) => {
    setPrivacyCheckbox(!privacyCheckbox);
  };

  const isValid =
    simultaneousTasks.length > 0 &&
    !formErrors.simultaneousTasks &&
    termsCheckbox &&
    privacyCheckbox;

  function handleScrollUp() {
    scroller.scrollTo('stepTitle', {
      smooth: true,
      offset: -300,
      duration: 500,
    });
  }

  function handleNextAndScroll(e) {
    handleNext(e);
    handleScrollUp();
  }

  function handleBackAndScroll(e) {
    handleBack(e);
    handleScrollUp();
  }

  return (
    <Stack>
      {/* Step Title */}
      <Typography
        variant="h3"
        sx={{ fontSize: '24px', fontWeight: 'regular', mb: '24px', mt: '32px' }}
        id="stepTitle"
      >
        Finalize
      </Typography>
      <Grid container justifyContent="space-between">
        <Grid item lg sx={{ mr: '56px' }}>
          <Stack spacing={4}>
            <Stack spacing={1}>
              <Typography
                variant="h3"
                sx={{ fontSize: '22px', fontWeight: '500' }}
              >
                Maximum number of simultaneous projects
              </Typography>
              <Typography
                variant="h3"
                sx={{ fontSize: '16px', color: 'text.secondary' }}
              >
                How many projects can you handle at one time and still deliver
                great results?
              </Typography>
              <TextField
                name="simultaneousTasks"
                placeholder="0"
                value={simultaneousTasks || ''}
                error={!!formErrors.simultaneousTasks}
                helperText={formErrors.simultaneousTasks}
                onChange={handleChange}
                required
                size="small"
              />
            </Stack>

            <Divider />

            <Stack spacing={1}>
              <Typography variant="h3" sx={{ fontSize: '20px' }}>
                Copyright Notice
              </Typography>
              <Typography
                variant="h3"
                sx={{ fontSize: '14px', color: 'text.secondary' }}
              >
                By submitting your project, you declare that you either own or
                have rights to the material posted and that posting these
                materials does not infringe on any third party's rights. You
                also acknowledge that you understand your project will be
                reviewed and evaluated by CryptBiz to ensure it meets CryptBiz's
                requirements.
              </Typography>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h3" sx={{ fontSize: '20px' }}>
                Terms and Service
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Checkbox
                  sx={{ p: 0 }}
                  onChange={handleTermsCheckbox}
                  required
                />
                <Typography
                  variant="h3"
                  sx={{ fontSize: '14px', color: 'text.secondary' }}
                >
                  I understand and agree to the{' '}
                  <Link>CryptBiz Terms of Service</Link>, including the{' '}
                  <Link>User Agreement</Link> and <Link>Privacy Policy</Link>.
                </Typography>
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h3" sx={{ fontSize: '20px' }}>
                Privacy Notice
              </Typography>
              <Stack direction="row" alignItems="flex-start" spacing={1}>
                <Checkbox
                  sx={{ p: 0 }}
                  onChange={handlePrivacyCheckbox}
                  required
                />
                <Typography
                  variant="h3"
                  sx={{ fontSize: '14px', color: 'text.secondary' }}
                >
                  By submitting this project and activating it, I understand
                  that it will appear in CryptBiz search results visible to the
                  general public and will show up in search engine results, even
                  if my profile visibility is set to Private or CryptBiz Users
                  Only.
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item sx={{ mt: { xs: '24px', lg: 0 } }}>
          <Card
            variant="outlined"
            sx={{ px: '32px', py: '24px', maxWidth: '310px' }}
          >
            <CardHeader
              title="Your project visibility"
              sx={{
                p: 0,
                '& .MuiTypography-root': {
                  fontSize: '20px',
                  fontWeight: 'medium',
                },
              }}
            />
            <CardContent
              sx={{
                p: 0,
                pb: 0,
                '&:last-child': {
                  pb: 0,
                },
              }}
            >
              <Typography variant="body1" sx={{ mt: '16px' }}>
                When you have reached your project maximum, your project will
                automatically be hidden from other clients. As soon as you
                complete an open project, your project will once again be
                viewable by clients.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Buttons */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          pt: 2,
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={handleBackAndScroll}>Back</Button>
        <Button
          variant="contained"
          onClick={isValid ? handleNextAndScroll : null}
          disabled={!isValid}
        >
          Save & Continue
        </Button>
      </Box>
    </Stack>
  );
}
