import { useState } from 'react';
import Typography from '@mui/material/Typography';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Box,
  Link,
  Button,
  CircularProgress,
  alpha,
} from '@mui/material';
import {
  Clear,
  Done,
  ImageOutlined,
  VideocamOutlined,
} from '@mui/icons-material';
import { scroller } from 'react-scroll';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import storage from '../../config/firebase';

const helpList = [
  'Blurry or distorted work',
  'Poorly cropped images',
  'Text-heavy images',
  'CryptiBiz logos or badges',
  'Referencing other companies or services',
  "Work that's not related to this project",
];

export function Step3({
  handleNext,
  handleChange,
  handleBack,
  values: { taskImage },
}) {
  const handleImageInput = () => {
    // Reset the file input value to allow selecting the same file
    document.getElementById('image-input').value = '';
  };
  const [file, setFile] = useState('');
  const [fileURL, setFileURL] = useState(taskImage);
  const [percent, setPercent] = useState(0);
  const [isUploaded, setIsUploaded] = useState(taskImage ? true : false);

  function handleImageChange(event) {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    const fileURL = URL.createObjectURL(selectedFile);
    setFileURL(fileURL);
  }

  function handleClearImage(e) {
    setFile('');
    setFileURL('');
    setIsUploaded(false);
    handleChange('');
  }

  function handleUpload() {
    if (!file) {
      alert('Please choose a file first!');
    }

    const storageRef = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setIsUploaded(true);
          setPercent(0);
          handleChange(url);
        });
      }
    );
  }

  function handleScrollUp() {
    scroller.scrollTo('stepTitle', {
      smooth: true,
      offset: -300,
      duration: 500,
    });
  }

  function handleNextAndScroll(e) {
    handleNext(e);
    handleScrollUp();
  }

  function handleBackAndScroll(e) {
    handleBack(e);
    handleScrollUp();
  }

  return (
    <Stack>
      {/* Step Title */}
      <Typography
        variant="h3"
        sx={{ fontSize: '24px', fontWeight: 'regular', mb: '24px', mt: '32px' }}
        id="stepTitle"
      >
        Gallery
      </Typography>
      <Grid container justifyContent="space-between">
        <Grid item lg sx={{ mr: '56px' }}>
          <Stack spacing={4}>
            <Stack spacing={1}>
              <Typography
                variant="h3"
                sx={{ fontSize: '22px', fontWeight: '500' }}
              >
                Project Images
              </Typography>
              <Typography
                variant="h3"
                sx={{ fontSize: '16px', color: 'text.secondary' }}
              >
                Upload up to 20 images (.jpg or .png), up to 10MB each and less
                than 4,000 pixels, in width or height.
              </Typography>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                sx={{ mt: '24px !important' }}
              >
                <Box
                  sx={{
                    bgcolor: '#F5F5F5',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '200px',
                    height: '150px',
                    gap: '8px',
                    borderRadius: '4px',
                    border: '1px solid #E4E5E7',
                    p: '10px',
                  }}
                >
                  <ImageOutlined sx={{ color: 'primary.main' }} />
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: '16px',
                      color: 'text.secondary',
                      textAlign: 'center',
                    }}
                  >
                    Drag & Drop a Photo here or
                  </Typography>
                  <Link
                    component="label"
                    onClick={handleImageInput}
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'none',
                      fontWeight: '500',
                    }}
                  >
                    Browse
                    <input
                      id="image-input"
                      type="file"
                      onChange={handleImageChange}
                      accept=""
                      hidden
                    />
                  </Link>
                </Box>
                <Box>
                  {fileURL && (
                    <Box sx={{ position: 'relative' }}>
                      <Box
                        sx={{
                          bgcolor: '#F5F5F5',
                          width: '200px',
                          height: '150px',
                          borderRadius: '4px',
                          border: '1px solid #E4E5E7',
                          backgroundImage: `url(${fileURL})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: '50%',
                        }}
                      />
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          backgroundColor: 'black',
                          opacity: 0.2,
                          borderRadius: '4px',
                        }}
                      ></Box>
                      <Clear
                        onClick={handleClearImage}
                        sx={{
                          position: 'absolute',
                          top: 2,
                          right: 2,
                          color: 'white',
                          cursor: 'pointer',
                        }}
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        <Box
                          sx={{
                            width: '32px',
                            height: '32px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <CircularProgress
                            sx={{
                              color: 'white',
                              cursor: 'pointer',
                            }}
                            variant="determinate"
                            size={32}
                            value={percent}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          display: isUploaded ? 'block' : 'none',
                        }}
                      >
                        <Box
                          sx={{
                            width: '32px',
                            height: '32px',
                            backgroundColor: alpha('#fff', 0.5),
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '50%',
                          }}
                        >
                          <Done
                            sx={{
                              color: 'white',
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Stack>
            </Stack>

            <Divider />

            <Stack spacing={1}>
              <Typography
                variant="h3"
                sx={{ fontSize: '24px', fontWeight: '500' }}
              >
                Project video
              </Typography>
              <Typography
                variant="h3"
                sx={{ fontSize: '16px', color: 'text.secondary' }}
              >
                Upload one video (.mp4), up to 100MB and less than 90 seconds.
                We recommend a video less than 60 seconds.
              </Typography>
              <Box
                sx={{
                  bgcolor: '#F5F5F5',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  maxWidth: '200px',
                  height: '150px',
                  gap: '8px',
                  borderRadius: '4px',
                  border: '1px solid #E4E5E7',
                  p: '10px',
                  mt: '24px !important',
                }}
              >
                <VideocamOutlined sx={{ color: 'primary.main' }} />
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: '16px',
                    color: 'text.secondary',
                    textAlign: 'center',
                  }}
                >
                  Drag & Drop a Video here or
                </Typography>
                <Link
                  component="label"
                  onClick={handleImageInput}
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    fontWeight: '500',
                  }}
                >
                  Browse
                  <input type="file" hidden />
                </Link>
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item sx={{ mt: { xs: '24px', lg: 0 } }}>
          <Card
            variant="outlined"
            sx={{ px: '32px', py: '24px', maxWidth: '310px' }}
          >
            <CardHeader
              title="Gallery guidelines"
              sx={{
                p: 0,
                '& .MuiTypography-root': {
                  fontSize: '20px',
                  fontWeight: 'medium',
                },
              }}
            />
            <CardContent
              sx={{
                p: 0,
                pb: 0,
                '&:last-child': {
                  pb: 0,
                },
              }}
            >
              <List
                sx={{
                  listStyleType: 'disc',
                  pl: '16px',
                  '& .MuiListItem-root': {
                    display: 'list-item',
                  },
                }}
              >
                {helpList.map((label, index) => (
                  <ListItem disablePadding key={index}>
                    <ListItemText
                      sx={{ color: 'primary.main', fontSize: '14px' }}
                    >
                      {label}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Buttons */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2,
          justifyContent: 'flex-end',
        }}
      >
        <Button color="inherit" onClick={handleBackAndScroll} sx={{ mr: 1 }}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={
            isUploaded || fileURL
              ? isUploaded
                ? handleNextAndScroll
                : handleUpload
              : handleUpload
          }
          disabled={!fileURL}
        >
          {isUploaded || fileURL
            ? isUploaded
              ? 'Save & Continue'
              : 'Upload'
            : 'Upload'}
        </Button>
      </Box>
    </Stack>
  );
}
