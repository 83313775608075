import { Typography, Grid } from '@mui/material';

import FeaturedCard from '../components/FeaturedCard';

export const Section6 = () => {
  return (
    <Grid
      container
      direction={{ xs: 'row', sm: 'column' }}
      sx={{ px: { sm: 0, lg: 8 }, mt: '150px' }}
    >
      <Grid item>
        <Typography
          variant="h2"
          color="text.primary"
          sx={{
            fontSize: '44px',
            fontWeight: '600',
            lineHeight: '48px',
            pt: '36px',
          }}
        >
          Featured in
        </Typography>
      </Grid>
      <Grid item sx={{ mt: '40px', ml: '8px' }}>
        <Grid container spacing={3}>
          <Grid item>
            <FeaturedCard />
          </Grid>
          <Grid item>
            <FeaturedCard />
          </Grid>
          <Grid item>
            <FeaturedCard />
          </Grid>
          <Grid item>
            <FeaturedCard />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
