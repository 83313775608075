import { Typography, Box, Stack, Link } from '@mui/material';
import Badge from '@mui/material/Badge';

import devIcon from '../../assets/dev-icon.png';

export const JobCircle = ({ title, links, img }) => {
  return (
    <Box
      sx={{
        display: 'flex ',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ margin: { xs: 'auto' } }}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            <Box
              sx={{
                bgcolor: 'primary.accent',
                width: '48px',
                height: '48px',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={img || devIcon}
                sx={{ width: '8px', maxWidth: '100%' }}
                alt=""
              />
            </Box>
          }
        >
          <Box
            sx={{
              bgcolor: '#E9E8FA',
              width: '120px',
              height: '120px',
              borderRadius: '50%',
            }}
          ></Box>
        </Badge>
      </Box>
      <Box sx={{ textAlign: { xs: 'center' }, mt: '8px' }}>
        <Typography
          variant="h6"
          sx={{
            fontSize: '24px',
            color: 'text.primary',
            fontWeight: '600',
            mt: '16px',
          }}
        >
          {title || 'Software Development'}
        </Typography>
        <Stack spacing="8px" sx={{ mt: '16px' }}>
          {links &&
            links.map((link, index) => (
              <Link href="/" sx={{ color: 'text.primary' }} key={index}>
                <Typography variant="body1">
                  {link || 'Data Analyst'}
                </Typography>
              </Link>
            ))}
        </Stack>
      </Box>
    </Box>
  );
};
