import { useState, useEffect } from 'react';
import { Box, Typography, Stack, Grid, Button } from '@mui/material';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Pagination from '@mui/material/Pagination';
import { scroller } from 'react-scroll';

import JobCard from '../../components/JobCard';
import { Section9 } from '../../sections/Section9';
import { jobs } from '../../data/jobs';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'transparent',
  border: '2px solid',
  borderColor: theme.palette.primary.outline,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderRight: 0,
  width: '100%',
  color: theme.palette.primary.outline,
  [theme.breakpoints.down('sm')]: {
    borderTopRightRadius: '9px',
    borderBottomRightRadius: '9px',
    borderRight: '2px solid',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create('width'),
    color: '#B2B2CA',
    '&::placeholder': {
      opacity: 1,
    },
  },
}));

const SearchIconWrapper = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  border: '2px solid',
  borderColor: theme.palette.primary.main,
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  '&:hover': {
    background: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
  },
}));

const jobsCategories = [
  'Web, Mobile & Software Dev',
  'Blockchain & Crypto',
  'Design & Creative',
  'NFT',
  'Writing',
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`job-tabpanel-${index}`}
      aria-labelledby={`job-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `job-tab-${index}`,
    'aria-controls': `job-tabpanel-${index}`,
  };
}

function Jobs() {
  const [searchQuery, setSearchQuery] = useState('');
  const [maxVisibleChips, setMaxVisibleChips] = useState(jobsCategories.length);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleJobChip = () => {
    console.log('handleJobChip');
  };

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  const [sort, setSort] = useState('Newest');
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    scroller.scrollTo('jobsContent', {
      smooth: true,
      offset: -300, // Adjust the offset value as needed
      duration: 500,
    });
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const getSortedJobs = () => {
    switch (sort) {
      case 'Newest':
        return [...jobs].sort((a, b) => new Date(b.date) - new Date(a.date));
      case 'Oldest':
        return [...jobs].sort((a, b) => new Date(a.date) - new Date(b.date));
      case 'Low Price':
        return [...jobs].sort((a, b) => a.price - b.price);
      case 'High Price':
        return [...jobs].sort((a, b) => b.price - a.price);
      default:
        return jobs;
    }
  };

  const sortedJobs = getSortedJobs().filter((job) => {
    if (currentTabIndex === 1) {
      // Filter for "Recent Jobs" tab
      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
      return new Date(job.date) >= twoDaysAgo;
    }
    // Filter based on search query
    return job.title.toLowerCase().includes(searchQuery.toLowerCase());
  });

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 600) {
        setMaxVisibleChips(3); // Set 3 visible chips for mobile
      } else if (screenWidth < 1024) {
        setMaxVisibleChips(4); // Set 5 visible chips for iPad or tablets
      } else {
        setMaxVisibleChips(jobsCategories.length); // Set 4 visible chips for other screen sizes
      }
    };

    handleResize(); // Call the function initially
    window.addEventListener('resize', handleResize); // Add event listener for resize

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, []);
  return (
    <>
      <Container maxWidth="xl">
        <Box>
          <Typography
            variant="h4"
            sx={{ fontSize: '30px', fontWeight: 'medium', mt: '32px' }}
          >
            Browse Jobs
          </Typography>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={{ xs: 0, md: 1.5 }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            sx={{ mt: '16px' }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: '14px',
                fontWeight: '600',
                marginBottom: { xs: '8px', md: '0px' },
              }}
            >
              Suggested
            </Typography>
            <Box
              sx={{
                maxWidth: '100%',
              }}
            >
              {jobsCategories.slice(0, maxVisibleChips).map((chip, index) => (
                <Chip
                  key={index}
                  label={chip}
                  sx={{
                    fontSize: { xs: '12px', md: '14px' },
                    color: 'text.primary',
                    borderColor: '#dadbdd',
                    marginRight: '8px',
                    marginBottom: { xs: '8px', md: '0px' },
                  }}
                  variant="outlined"
                  onClick={handleJobChip}
                />
              ))}
            </Box>
          </Stack>
        </Box>
        {/* Search Bar */}
        <Box
          sx={{
            display: 'flex',
            mt: '16px',
          }}
        >
          <Search>
            <StyledInputBase
              placeholder="Search for jobs"
              inputProps={{ 'aria-label': 'search' }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Search>
          <SearchIconWrapper
            sx={{
              display: { xs: 'none', sm: 'flex' },
              alignItems: 'center',
              px: '12px',
              cursor: 'pointer',
            }}
          >
            <SearchIcon sx={{ color: 'common.white' }} />
          </SearchIconWrapper>
        </Box>
        <Box
          sx={{
            border: '2px solid',
            borderColor: 'primary.outline',
            borderRadius: '9px',
            mt: '32px',
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontSize: '22px', fontWeight: 'medium', p: '24px', pb: 0 }}
          >
            Jobs you might like
          </Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: '16px' }}>
            <Tabs
              value={currentTabIndex}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Best Matches"
                sx={{ fontSize: { xs: '14px' } }}
                {...a11yProps(0)}
              />
              <Tab
                label="Saved Jobs (0)"
                sx={{ fontSize: { xs: '14px' } }}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          {/* Best Matches Tab */}
          {currentTabIndex === 0 && (
            <Box sx={{ p: { xs: '16px', sm: '24px' } }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ pb: '16px' }}
              >
                {sortedJobs.length === 0
                  ? 'No Jobs Available'
                  : sortedJobs.length + ' Jobs Available'}
                {sortedJobs.length !== 0 && (
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      value={sort}
                      onChange={handleSortChange}
                      size="small"
                      name="sort-form"
                    >
                      <MenuItem value="Newest">Sort: Newest</MenuItem>
                      <MenuItem value="Oldest">Sort: Oldest</MenuItem>
                      <MenuItem value="Low Price">Sort: Low Price</MenuItem>
                      <MenuItem value="High Price">Sort: High Price</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Stack>
              {sortedJobs.length !== 0 && (
                <>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'grid',
                      pb: '32px',
                    }}
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(2, 1fr)',
                      md: 'repeat(3, 1fr)',
                      lg: 'repeat(4, 1fr)',
                    }}
                    gap={4}
                    justifyContent="space-between"
                    id="jobsContent"
                  >
                    {sortedJobs
                      .slice((currentPage - 1) * 12, currentPage * 12)
                      .map((job) => (
                        <Grid item key={job.id}>
                          <JobCard {...job} />
                        </Grid>
                      ))}
                  </Box>
                  <Box
                    sx={{ p: '24px', pt: '0', display: 'flex' }}
                    justifyContent="center"
                  >
                    <Pagination
                      count={Math.ceil(sortedJobs.length / 12)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </Box>
                </>
              )}
            </Box>
          )}

          {currentTabIndex === 1 && (
            <Box sx={{ p: { xs: '16px', sm: '24px' } }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ pb: '16px' }}
              >
                No items found in your saved list.
              </Stack>
            </Box>
          )}
        </Box>
        <Section9 />
      </Container>
    </>
  );
}

export default Jobs;
