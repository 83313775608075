import { Typography, Grid } from '@mui/material';

import Gig from '../components/Gig';

export const Section5 = () => {
  return (
    <Grid
      container
      direction={{ xs: 'row', sm: 'column' }}
      sx={{ px: { sm: 0, lg: 8 }, mt: '180px' }}
      justifyContent="center"
    >
      <Grid item>
        <Typography
          variant="h2"
          color="text.primary"
          sx={{
            fontSize: '44px',
            fontWeight: 'medium',
            lineHeight: '48px',
            pt: '36px',
            textAlign: 'center',
          }}
        >
          Sellers Catalog
        </Typography>
        <Typography
          component="p"
          color="text.secondary"
          sx={{ fontSize: '22px', pt: '16px', textAlign: 'center' }}
        >
          We have hundreds of featured sellers
        </Typography>
      </Grid>
      <Grid item sx={{ mt: '24px' }}>
        <Grid container justifyContent="center" spacing={2.5}>
          <Grid item>
            <Gig />
          </Grid>
          <Grid item>
            <Gig />
          </Grid>
          <Grid item>
            <Gig />
          </Grid>
          <Grid item>
            <Gig />
          </Grid>
          <Grid item>
            <Gig />
          </Grid>
          <Grid item>
            <Gig />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
