import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#4169E1',
      dark: '#253E89',
      accent: '#2314C9',
      light: '#D1D1E3',
      footer: '#DEDCF7',
      stars: '#FF7A00',
      outline: '#E4E5E7',
    },
    text: {
      primary: '#42526E',
      secondary: '#606F85',
      disabled: '#7F8898',
      dark: '#1B1E2C',
    },
    custom: {
      main: '#606F85',
    },
    custom1: {
      main: '#DADADA',
    },
    _primary: {
      main: '#4169E1',
    },
  },
  typography: {
    fontFamily: `"Work Sans Variable", "Helvetica", "Arial", sans-serif`,
    button: {
      textTransform: 'none',
      boxShadow: 'none',
      fontSize: '16px',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
  shape: {
    borderRadius: 9,
  },
});
