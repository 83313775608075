import { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Stack } from '@mui/material';

import { Step1 } from '../../components/TasksSteps/Step1';
import { Step2 } from '../../components/TasksSteps/Step2';
import { Step3 } from '../../components/TasksSteps/Step3';
import { Step4 } from '../../components/TasksSteps/Step4';
import { Step5 } from '../../components/TasksSteps/Step5';
import { Step6 } from '../../components/TasksSteps/Step6';
import formValidation from '../../helper/formValidation';
import { collection, setDoc, doc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';

const steps = ['', '', '', '', '', ''];
const initialValues = {
  taskTitle: '',
  taskTags: '',
  basicTitle: '',
  standardTitle: '',
  premiumTitle: '',
  basicDescription: '',
  standardDescription: '',
  premiumDescription: '',
  basicDelivery: '',
  standardDelivery: '',
  premiumDelivery: '',
  basicRevisions: '',
  standardRevisions: '',
  premiumRevisions: '',
  basicPrice: '',
  standardPrice: '',
  premiumPrice: '',
  taskImage: '',
  taskRequirements: '',
  isRequirementsMandatory: false,
  taskSummary: '',
  simultaneousTasks: '',
  category: '',
  subcategory: '',
  isCompletePackage: false,
  basicConceptsIncluded: '',
  standardConceptsIncluded: '',
  premiumConceptsIncluded: '',

  basicNumberOfPages: '',
  standardNumberOfPages: '',
  premiumNumberOfPages: '',

  basicSourceFile: false,
  standardSourceFile: false,
  premiumSourceFile: false,

  basicThreedMockup: false,
  standardThreedMockup: false,
  premiumThreedMockup: false,

  basicLogoTransparency: false,
  standardLogoTransparency: false,
  premiumLogoTransparency: false,

  basicResponsiveDesign: false,
  standardResponsiveDesign: false,
  premiumResponsiveDesign: false,

  basicContentUpload: false,
  standardContentUpload: false,
  premiumContentUpload: false,

  basicEcommerceFunctionality: false,
  standardEcommerceFunctionality: false,
  premiumEcommerceFunctionality: false,

  basicIncludeDesign: false,
  standardIncludeDesign: false,
  premiumIncludeDesign: false,

  basicIncludeAnimation: false,
  standardIncludeAnimation: false,
  premiumIncludeAnimation: false,

  basicNumberOfLevels: '',
  standardNumberOfLevels: '',
  premiumNumberOfLevels: '',
};

const fieldsValidation = {
  taskTitle: {
    error: '',
    validate: 'title',
    minLength: 15,
    maxLength: 90,
  },
  taskTags: {
    error: '',
    validate: 'tag',
    minLength: 6,
    maxLength: 80,
  },
  basicTitle: {
    error: '',
    validate: 'text',
    minLength: 4,
    maxLength: 30,
  },
  standardTitle: {
    error: '',
    validate: 'text',
    minLength: 4,
    maxLength: 30,
  },
  premiumTitle: {
    error: '',
    validate: 'text',
    minLength: 4,
    maxLength: 30,
  },
  basicDescription: {
    error: '',
    validate: 'text',
    minLength: 5,
    maxLength: 90,
  },
  standardDescription: {
    error: '',
    validate: 'text',
    minLength: 5,
    maxLength: 90,
  },
  premiumDescription: {
    error: '',
    validate: 'text',
    minLength: 5,
    maxLength: 90,
  },
  basicDelivery: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  standardDelivery: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  premiumDelivery: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  basicRevisions: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  standardRevisions: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  premiumRevisions: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  basicPrice: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 4,
    isPrice: true,
  },
  standardPrice: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 4,
    isPrice: true,
  },
  premiumPrice: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 4,
    isPrice: true,
  },
  taskImage: {
    error: '',
    validate: 'url',
  },
  taskRequirements: {
    error: '',
    validate: 'text',
    minLength: 8,
    maxLength: 250,
  },
  taskSummary: {
    error: '',
    validate: 'bigText',
    minLength: 64,
    maxLength: 1200,
  },
  simultaneousTasks: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  category: {
    error: '',
    validate: 'text',
  },
  subcategory: {
    error: '',
    validate: 'text',
  },
  basicConceptsIncluded: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  standardConceptsIncluded: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  premiumConceptsIncluded: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  basicNumberOfPages: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  standardNumberOfPages: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  premiumNumberOfPages: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  basicSourceFile: {},
  standardSourceFile: {},
  premiumSourceFile: {},
  basicThreedMockup: {},
  standardThreedMockup: {},
  premiumThreedMockup: {},
  basicLogoTransparency: {},
  standardLogoTransparency: {},
  premiumLogoTransparency: {},
  basicNumberOfLevels: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  standardNumberOfLevels: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
  premiumNumberOfLevels: {
    error: '',
    validate: 'number',
    minLength: 1,
    maxLength: 2,
    ignoreZero: true,
  },
};

async function saveTask(data, navigate) {
  try {
    const docRef = doc(collection(db, 'tasks'), data.id);

    await setDoc(docRef, { data: data });
    navigate('/tasks/', data.id);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

export default function NewTask() {
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function generateId() {
    return Math.random().toString(36).substr(2, 9);
  }

  function generateFakeUsername() {
    const adjectives = ['Cool', 'Awesome', 'Amazing', 'Fantastic', 'Epic'];
    const nouns = ['Unicorn', 'Ninja', 'Wizard', 'Jedi', 'Pirate'];

    const randomAdjective =
      adjectives[Math.floor(Math.random() * adjectives.length)];
    const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];

    return randomAdjective + ' ' + randomNoun;
  }

  function generateFakeRating() {
    const wholeNumber = Math.floor(Math.random() * 5) + 1;
    const decimal = Math.floor(Math.random() * 2) * 0.5;
    return wholeNumber + decimal;
  }

  function generateFakeRatingCount() {
    return Math.floor(Math.random() * 300);
  }

  function generateFakePurchases() {
    return Math.floor(Math.random() * 50);
  }

  function dateNow() {
    const timestamp = Date.now();
    const date = new Date(timestamp);

    // Extract day, month, and year from the date object
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Create the formatted date string
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  function gameArtFeatures() {
    const features = {
      basicFeatures: [],
      standardFeatures: [],
      premiumFeatures: [],
    };

    // Concepts included
    if (formValues.basicConceptsIncluded) {
      features.basicFeatures.push({
        title:
          formValues.basicConceptsIncluded +
          ' concept' +
          (formValues.basicConceptsIncluded === '1'
            ? ' included'
            : 's included'),
        isChecked: true,
      });
      if (formValues.isCompletePackage && formValues.standardConceptsIncluded) {
        features.standardFeatures.push({
          title:
            formValues.standardConceptsIncluded +
            ' concept' +
            (formValues.standardConceptsIncluded === '1'
              ? ' included'
              : 's included'),
          isChecked: true,
        });
      }
      if (formValues.isCompletePackage && formValues.premiumConceptsIncluded) {
        features.premiumFeatures.push({
          title:
            formValues.premiumConceptsIncluded +
            ' concept' +
            (formValues.premiumConceptsIncluded === '1'
              ? ' included'
              : 's included'),
          isChecked: true,
        });
      }
    }
    return features;
  }

  function logoDesignFeatures() {
    const features = {
      basicFeatures: [],
      standardFeatures: [],
      premiumFeatures: [],
    };

    // Concepts included
    if (formValues.basicConceptsIncluded) {
      features.basicFeatures.push({
        title:
          formValues.basicConceptsIncluded +
          ' concept' +
          (formValues.basicConceptsIncluded === '1'
            ? ' included'
            : 's included'),
        isChecked: true,
      });
      if (formValues.isCompletePackage && formValues.standardConceptsIncluded) {
        features.standardFeatures.push({
          title:
            formValues.standardConceptsIncluded +
            ' concept' +
            (formValues.standardConceptsIncluded === '1'
              ? ' included'
              : 's included'),
          isChecked: true,
        });
      }
      if (formValues.isCompletePackage && formValues.premiumConceptsIncluded) {
        features.premiumFeatures.push({
          title:
            formValues.premiumConceptsIncluded +
            ' concept' +
            (formValues.premiumConceptsIncluded === '1'
              ? ' included'
              : 's included'),
          isChecked: true,
        });
      }
    }

    // Source File
    features.basicFeatures.push({
      title: 'Include source file',
      isChecked: formValues.basicSourceFile,
    });
    if (formValues.isCompletePackage) {
      features.standardFeatures.push({
        title: 'Include  source file',
        isChecked: formValues.standardSourceFile,
      });
      features.premiumFeatures.push({
        title: 'Include  source file',
        isChecked: formValues.premiumSourceFile,
      });
    }

    // 3D Mockup
    features.basicFeatures.push({
      title: '3D Mockup',
      isChecked: formValues.basicThreedMockup,
    });
    if (formValues.isCompletePackage) {
      features.standardFeatures.push({
        title: '3D Mockup',
        isChecked: formValues.standardThreedMockup,
      });
      features.premiumFeatures.push({
        title: '3D Mockup',
        isChecked: formValues.premiumThreedMockup,
      });
    }

    // Logo transparency
    features.basicFeatures.push({
      title: 'Logo transparency',
      isChecked: formValues.basicLogoTransparency,
    });
    if (formValues.isCompletePackage) {
      features.standardFeatures.push({
        title: 'Logo transparency',
        isChecked: formValues.standardLogoTransparency,
      });
      features.premiumFeatures.push({
        title: 'Logo transparency',
        isChecked: formValues.premiumLogoTransparency,
      });
    }

    return features;
  }

  function gameDevelopmentFeatures() {
    const features = {
      basicFeatures: [],
      standardFeatures: [],
      premiumFeatures: [],
    };

    // Number of levels
    if (formValues.basicNumberOfLevels) {
      features.basicFeatures.push({
        title:
          formValues.basicNumberOfLevels +
          ' level' +
          (formValues.basicNumberOfLevels === '1' ? '' : 's'),
        isChecked: true,
      });
      if (formValues.isCompletePackage && formValues.standardNumberOfLevels) {
        features.standardFeatures.push({
          title:
            formValues.standardNumberOfLevels +
            ' level' +
            (formValues.standardNumberOfLevels === '1' ? '' : 's'),
          isChecked: true,
        });
      }
      if (formValues.isCompletePackage && formValues.premiumNumberOfLevels) {
        features.premiumFeatures.push({
          title:
            formValues.premiumNumberOfLevels +
            ' level' +
            (formValues.premiumNumberOfLevels === '1' ? '' : 's'),
          isChecked: true,
        });
      }
    }

    // Include animation
    features.basicFeatures.push({
      title: 'Include animation',
      isChecked: formValues.basicIncludeAnimation,
    });
    features.standardFeatures.push({
      title: 'Include animation',
      isChecked: formValues.standardIncludeAnimation,
    });
    features.premiumFeatures.push({
      title: 'Include animation',
      isChecked: formValues.premiumIncludeAnimation,
    });

    // Include design
    features.basicFeatures.push({
      title: 'Include design',
      isChecked: formValues.basicIncludeDesign,
    });
    features.standardFeatures.push({
      title: 'Include design',
      isChecked: formValues.standardIncludeDesign,
    });
    features.premiumFeatures.push({
      title: 'Include design',
      isChecked: formValues.premiumIncludeDesign,
    });

    // Include source code
    features.basicFeatures.push({
      title: 'Include source code',
      isChecked: formValues.basicSourceFile,
    });
    features.standardFeatures.push({
      title: 'Include source code',
      isChecked: formValues.standardSourceFile,
    });
    features.premiumFeatures.push({
      title: 'Include source code',
      isChecked: formValues.premiumSourceFile,
    });

    return features;
  }

  function websiteDevelopmentFeatures() {
    const features = {
      basicFeatures: [],
      standardFeatures: [],
      premiumFeatures: [],
    };

    // Number of pages
    if (formValues.basicNumberOfPages) {
      features.basicFeatures.push({
        title:
          formValues.basicNumberOfLevels +
          ' page' +
          (formValues.basicNumberOfLevels === '1' ? '' : 's'),
        isChecked: true,
      });
      if (formValues.isCompletePackage && formValues.standardNumberOfPages) {
        features.standardFeatures.push({
          title:
            formValues.standardNumberOfPages +
            ' page' +
            (formValues.standardNumberOfPages === '1' ? '' : 's'),
          isChecked: true,
        });
      }
      if (formValues.isCompletePackage && formValues.premiumNumberOfPages) {
        features.premiumFeatures.push({
          title:
            formValues.premiumNumberOfPages +
            ' page' +
            (formValues.premiumNumberOfPages === '1' ? '' : 's'),
          isChecked: true,
        });
      }
    }

    // E-commerce functionality
    features.basicFeatures.push({
      title: 'E-commerce functionality',
      isChecked: formValues.basicEcommerceFunctionality,
    });
    features.standardFeatures.push({
      title: 'E-commerce functionality',
      isChecked: formValues.standardEcommerceFunctionality,
    });
    features.premiumFeatures.push({
      title: 'E-commerce functionality',
      isChecked: formValues.premiumEcommerceFunctionality,
    });

    // Responsive design
    features.basicFeatures.push({
      title: 'Responsive design',
      isChecked: formValues.basicResponsiveDesign,
    });
    features.standardFeatures.push({
      title: 'Responsive design',
      isChecked: formValues.standardResponsiveDesign,
    });
    features.premiumFeatures.push({
      title: 'Responsive design',
      isChecked: formValues.premiumResponsiveDesign,
    });

    // Content upload
    features.basicFeatures.push({
      title: 'Content upload',
      isChecked: formValues.basicContentUpload,
    });
    features.standardFeatures.push({
      title: 'Content upload',
      isChecked: formValues.standardContentUpload,
    });
    features.premiumFeatures.push({
      title: 'Content upload',
      isChecked: formValues.premiumContentUpload,
    });
    return features;
  }

  const featuresBySubcategory = () => {
    if (formValues.subcategory) {
      switch (formValues.subcategory) {
        case 'Logo Design':
          return logoDesignFeatures();
        case 'Game Art':
          return gameArtFeatures();
        case 'Website Development':
          return websiteDevelopmentFeatures();
        case 'Game Development':
          return gameDevelopmentFeatures();
        default:
          break;
      }
    }
  };

  const handleSubmit = () => {
    const data = {
      id: generateId(),
      userName: generateFakeUsername(),
      userImage: 'https://thispersondoesnotexist.com/',
      levelStatus: 'Level 1 Seller',
      title: formValues.taskTitle,
      rating: generateFakeRating(),
      ratingCount: generateFakeRatingCount(),
      isCompletePackage: formValues.isCompletePackage,
      basicTitle: formValues.basicTitle,
      standardTitle: formValues.standardTitle,
      premiumTitle: formValues.premiumTitle,
      basicPrice: formValues.basicPrice,
      standardPrice: formValues.standardPrice,
      premiumPrice: formValues.premiumPrice,
      basicDelivery: formValues.basicDelivery,
      standardDelivery: formValues.standardDelivery,
      premiumDelivery: formValues.premiumDelivery,
      basicRevision: formValues.basicRevisions,
      standardRevision: formValues.standardRevisions,
      premiumRevision: formValues.premiumRevisions,
      basicPurchases: generateFakePurchases(),
      standardPurchases: generateFakePurchases(),
      premiumPurchases: generateFakePurchases(),
      basicDescription: formValues.basicDescription,
      standardDescription: formValues.standardDescription,
      premiumDescription: formValues.premiumDescription,
      basicFeatures: featuresBySubcategory().basicFeatures,
      standardFeatures: featuresBySubcategory().standardFeatures,
      premiumFeatures: featuresBySubcategory().premiumFeatures,
      tags: formValues.taskTags,
      category: formValues.category,
      subCategory: formValues.subcategory,
      date: dateNow(),
      imgUrl: formValues.taskImage,
      videoUrl: '',
      online: Math.random() < 0.5,
      isFavorited: Math.random() < 0.5,
      isRequirementsRequired: formValues.isRequirementsMandatory,
      summary: formValues.taskSummary,
      simultaneousTasks: formValues.simultaneousTasks,
      acceptedPayments: 'ETH, USDT, +21',
    };

    console.log(data);
    saveTask(data, navigate);
  };

  const handleToggleChange = (e) => {
    const value = e.target.checked;
    const name = e.target.name;

    console.log(name + ' ' + value);
    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target.name);
    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Set errors
    const error = formValidation(name, value, fieldsValidation) || '';

    setFormErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleImageChange = (url) => {
    const value = url;

    // Set values
    setFormValues((prev) => ({
      ...prev,
      taskImage: value,
    }));
  };

  const handleCategoryChange = (e) => {
    const value = e.target.value;

    // Set values
    setFormValues((prev) => ({
      ...prev,
      category: value,
      subcategory: '',
    }));
  };

  const handleSubcategoryChange = (e) => {
    const value = e.target.value;

    // Set values
    setFormValues((prev) => ({
      ...prev,
      subcategory: value,
    }));
  };

  const handleQuillChange = (value) => {
    // Set values
    setFormValues((prev) => ({
      ...prev,
      taskSummary: value,
    }));

    // Set errors
    const error = formValidation('taskSummary', value, fieldsValidation) || '';

    setFormErrors((prev) => ({
      ...prev,
      taskSummary: error,
    }));
  };

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h2"
        sx={{ fontSize: '32px', fontWeight: 'medium', my: '32px' }}
      >
        Create New Task
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <Stack justifyContent="center" mt={12} mb={18}>
            <Typography
              variant="h2"
              textAlign={'center'}
              fontSize={'40px'}
              fontWeight={'600'}
            >
              Almost here...
            </Typography>
            <Typography
              variant="h2"
              textAlign={'center'}
              fontSize={'28px'}
              fontWeight={'500'}
            >
              Let's publish your Task and get some buyers rolling in.
            </Typography>
            {/* Buttons */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                pt: 4,
                justifyContent: 'flex-end',
              }}
            >
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                onClick={
                  activeStep === steps.length ? handleSubmit : handleNext
                }
              >
                {activeStep === steps.length
                  ? 'Publish Task'
                  : 'Save & Continue'}
              </Button>
            </Box>
          </Stack>
        ) : (
          <>
            {activeStep === 0 && (
              <Step1
                handleNext={handleNext}
                handleChange={handleChange}
                handleSubcategoryChange={handleSubcategoryChange}
                handleCategoryChange={handleCategoryChange}
                values={formValues}
                formErrors={formErrors}
              />
            )}
            {activeStep === 1 && (
              <Step2
                handleNext={handleNext}
                handleChange={handleChange}
                handleBack={handleBack}
                handleToggleChange={handleToggleChange}
                values={formValues}
                formErrors={formErrors}
              />
            )}
            {activeStep === 2 && (
              <Step3
                handleNext={handleNext}
                handleChange={handleImageChange}
                handleBack={handleBack}
                values={formValues}
              />
            )}
            {activeStep === 3 && (
              <Step4
                handleNext={handleNext}
                handleChange={handleChange}
                handleBack={handleBack}
                handleToggleChange={handleToggleChange}
                values={formValues}
                formErrors={formErrors}
              />
            )}
            {activeStep === 4 && (
              <Step5
                handleNext={handleNext}
                handleChange={handleQuillChange}
                handleBack={handleBack}
                values={formValues}
                formErrors={formErrors}
              />
            )}
            {activeStep === 5 && (
              <Step6
                handleNext={handleNext}
                handleChange={handleChange}
                handleBack={handleBack}
                values={formValues}
                formErrors={formErrors}
              />
            )}
          </>
        )}
      </Box>
    </Container>
  );
}
