import { Box } from '@mui/material';
import Container from '@mui/material/Container';

import { Section1 } from '../../sections/Section1';
import { Section2 } from '../../sections/Section2';
import { Section3 } from '../../sections/Section3';
import { Section4 } from '../../sections/Section4';
import { Section5 } from '../../sections/Section5';
import { Section6 } from '../../sections/Section6';
import { Section7 } from '../../sections/Section7';
import { Section8 } from '../../sections/Section8';
import { Section9 } from '../../sections/Section9';

function Home() {
  return (
    <>
      <Container maxWidth="xl" sx={{ mt: { sm: 5, md: 10 } }}>
        <Box>
          <Section1 />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section5 />
          <Section6 />
          <Section7 />
        </Box>
      </Container>
      <Section8 />
      <Section9 />
    </>
  );
}

export default Home;
