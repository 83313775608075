import { Box, Button, Stack } from '@mui/material';
import Container from '@mui/material/Container';

import ArticleCard from '../../components/ArticleCard';
import { ArrowForward } from '@mui/icons-material';

function Blog() {
  return (
    <Container maxWidth="lg" sx={{ mt: { xs: 4, md: 6 } }}>
      <Stack justifyContent="center" spacing={3}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          rowGap={3}
          columnGap={4}
          sx={{ px: '32px' }}
        >
          <Box gridColumn="span 2/span 2">
            <ArticleCard />
          </Box>
          <Box gridColumn={{ xs: 'span 2/span 2', md: 'span 1/span 1' }}>
            <ArticleCard />
          </Box>
          <Box gridColumn={{ xs: 'span 2/span 2', md: 'span 1/span 1' }}>
            <ArticleCard />
          </Box>
          <Box gridColumn={{ xs: 'span 2/span 2', md: 'span 1/span 1' }}>
            <ArticleCard />
          </Box>
          <Box gridColumn={{ xs: 'span 2/span 2', md: 'span 1/span 1' }}>
            <ArticleCard />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            variant="outlined"
            size="large"
            endIcon={<ArrowForward />}
            sx={{
              py: '12px',
              borderColor: 'primary.main',
              borderWidth: '2px',
              '&:hover': {
                borderWidth: '2px',
              },
            }}
          >
            Older Posts
          </Button>
        </Box>
      </Stack>
    </Container>
  );
}

export default Blog;
