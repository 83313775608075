import { Routes as WebRoutes, Route } from 'react-router';

import Home from './pages/Home';
import Jobs from './pages/Jobs';
import Tasks from './pages/Tasks';
import Blog from './pages/Blog';
import FulltimeJobs from './pages/FulltimeJobs';
import HowItWorks from './pages/HowItWorks';
import NewTask from './pages/NewTask';
import SingleTask from './pages/SingleTask';

export default function Routes() {
  return (
    <WebRoutes>
      <Route path="/" Component={Home} exact></Route>
      <Route path="jobs" Component={Jobs} exact></Route>
      <Route path="blog" Component={Blog} exact></Route>
      <Route path="tasks" Component={Tasks} exact></Route>
      <Route path="tasks/:taskId" element={<SingleTask />} exact></Route>
      <Route path="how-it-works" Component={HowItWorks} exact></Route>
      <Route path="fulltime-jobs" Component={FulltimeJobs} exact></Route>
      <Route path="tasks/new" Component={NewTask} exact></Route>
    </WebRoutes>
  );
}
