import { useState, useRef, useEffect } from 'react';
import { Typography, Box, Stack, Avatar, IconButton } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Rating from '@mui/material/Rating';
import { Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { FavoriteRounded } from '@mui/icons-material';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    minWidth: '5px',
    height: '5px',
    top: 0,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

function JobCard({
  userName,
  levelStatus,
  title,
  rating,
  ratingCount,
  price,
  imgUrl,
  online,
  location,
  isFavorited,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [isMarkedAsFavorited, setisMarkedAsFavorited] = useState(isFavorited);
  const titleRef = useRef(null);

  const handleFavorite = () => {
    setisMarkedAsFavorited(!isMarkedAsFavorited);
  };

  useEffect(() => {
    if (titleRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(titleRef.current).lineHeight
      );
      const maxLines = 2;
      const titleHeight = titleRef.current.clientHeight;

      if (titleHeight > lineHeight * maxLines) {
        titleRef.current.style.webkitLineClamp = `${maxLines}`;
        titleRef.current.style.webkitBoxOrient = 'vertical';
        titleRef.current.style.overflow = 'hidden';
      }
    }
  }, []);
  return (
    <Card
      sx={{
        boxShadow: 'none',
        border: '1px solid',
        borderColor: 'primary.light',
        minWidth: '245px',
        width: '100%',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box sx={{ position: 'relative' }}>
        <Link
          href="/"
          sx={{
            transition: 'opacity .2s ease-in-out',
            '&:hover': {
              opacity: '.8',
            },
          }}
        >
          <CardMedia
            component="img"
            alt=""
            sx={{ width: '100%', height: '100%' }}
            image={
              imgUrl ||
              'https://fakeimg.pl/245x370/ebebf3/9292D4?text=Placeholder&font=bebas'
            }
          />
        </Link>
        {isMarkedAsFavorited ? (
          <Tooltip title="Unsave">
            <IconButton
              sx={{ position: 'absolute', top: 8, right: 8 }}
              onClick={handleFavorite}
            >
              <FavoriteRounded
                sx={{
                  cursor: 'pointer',
                  color: '#E24141',
                }}
              />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            onClick={handleFavorite}
          >
            <Tooltip title="Save to list">
              <FavoriteBorderOutlinedIcon
                sx={{
                  display: { xs: 'block', lg: isHovered ? 'block' : 'none' },
                  cursor: 'pointer',
                  color: '#9292D4',
                }}
              />
            </Tooltip>
          </IconButton>
        )}
      </Box>
      <CardContent sx={{ pb: 0 }}>
        <Stack direction="row" spacing={1.5}>
          {online ? (
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              <Avatar
                alt={userName || 'Shanks'}
                src="/static/images/avatar/1.jpg"
                sx={{ background: '#D1D1E3', width: '40px', height: '40px' }}
              />
            </StyledBadge>
          ) : (
            <Avatar
              alt={userName || 'Shanks'}
              src="/static/images/avatar/1.jpg"
              sx={{ background: '#D1D1E3', width: '40px', height: '40px' }}
            />
          )}
          <Stack>
            <Link
              href="/"
              sx={{
                textDecoration: 'none',
                fontWeight: 'medium',
                fontSize: '16px',
                color: 'text.primary',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {userName || 'Shanks'}
              <Typography variant="h3"></Typography>
            </Link>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 'medium',
                fontSize: '12px',
                color: 'text.secondary',
              }}
            >
              {levelStatus || 'Level 1 Seller'}
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ mt: '16px' }} spacing={0.5}>
          <Link
            href="/"
            sx={{
              textDecoration: 'none',
              color: 'text.primary',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <Typography
              ref={titleRef}
              variant="h3"
              color="text.primary"
              sx={{
                fontSize: '16px',
                fontWeight: '400',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {title ||
                'I will fork crypto exchange website like pancakeswap and uniswap'}
            </Typography>
          </Link>
          <Typography
            variant="body1"
            sx={{ fontSize: '14px', fontWeight: '400', color: '#ccc' }}
          >
            {location || 'Morocco'}
          </Typography>
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between', px: '16px' }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          sx={{ color: 'primary.stars' }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {parseInt(rating) || 5.0}
          </Typography>
          <Stack direction="row">
            <Rating
              name="read-only"
              value={parseInt(rating) || '5.0'}
              size="small"
              readOnly
              sx={{ color: 'primary.stars' }}
            />
          </Stack>
          <Typography variant="body2" color="text.secondary">
            ({ratingCount || '2,890'})
          </Typography>
        </Stack>
        <Box>
          <Typography
            variant="h5"
            component="div"
            sx={{ fontWeight: '600', fontSize: '18px' }}
          >
            ${price || '120'}
          </Typography>
        </Box>
      </CardActions>
    </Card>
  );
}

export default JobCard;
