import { Typography, Stack, Avatar, Link, IconButton } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Circle } from '@mui/icons-material';

function ArticleCard() {
  const handleAvatarClick = () => {};
  return (
    <Card
      sx={{
        boxShadow: 'none',
      }}
    >
      <Link
        href="/"
        sx={{
          transition: 'opacity .2s ease-in-out',
          '&:hover': {
            opacity: '.8',
          },
        }}
      >
        <CardMedia
          component="img"
          alt=""
          height="300"
          image="https://fakeimg.pl/900x300/ebebf3/9292D4?text=PS&font=bebas"
          sx={{ borderRadius: '10px', position: 'relative' }}
        />
      </Link>
      <CardContent sx={{ py: '10px', px: 0 }}>
        <Stack sx={{ mt: '12px' }}>
          <Link
            href="/"
            sx={{
              textDecoration: 'none',
              color: 'text.primary',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <Typography
              variant="h3"
              color="text.primary"
              sx={{ fontSize: '20px', fontWeight: '600' }}
            >
              How to Improve Your Task’s Ranking and Land More Offers
            </Typography>
          </Link>
          <Typography
            variant="body2"
            color="text.disabled"
            sx={{
              mt: '6px',
              fontSize: '15px',
              lineHeight: '1.6rem',
              display: '-webkit-box',
              WebkitLineClamp: '4',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            TL;DR: Applications for the fourth cohort of EPF are now opened! The
            EPF Team will hold a town hall on June 12 at 4:30PM UTC Submit your
            application here before June 16th Greetings, Ethereum community! We
            are excited to announce that the application period for the fourth
            cohort of the Ethereum Protocol Fellowship EPF is officially open!
            The EPF is an initiative aimed at supporting the development of
            Ethereum's core protocol by providing a pathway for aspiring
            protocol contributors to gain the experience needed to make
            meaningful contributions. This program is specifically tailored
            toward developers and researchers who have an unyielding passion for
            Ethereum, its community, and the possibilities of decentralized
            technology. In each cohort, we seek to assemble a diverse group of
            fellows
          </Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{ mt: '16px' }}
          alignItems="center"
          spacing={1}
        >
          <IconButton onClick={handleAvatarClick}>
            <Avatar
              alt="Shanks"
              src="https://thispersondoesnotexist.com/"
              sx={{ background: '#D1D1E3', width: '36px', height: '36px' }}
            />
          </IconButton>
          <Stack direction="row" spacing={1}>
            <Typography
              variant="body1"
              sx={{
                color: 'text.secondary',
                fontSize: '14px',
              }}
            >
              by
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: '600',
                color: 'text.primary',
                fontSize: '14px',
              }}
            >
              James Priyo
            </Typography>
          </Stack>
          <Circle sx={{ fontSize: '8px', color: '#ccc' }} />
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ fontSize: '14px' }}
          >
            8min read
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default ArticleCard;
