import React from 'react';
import {
  Checkbox,
  TextField,
  Typography,
  TableRow,
  TableCell,
} from '@mui/material';

/*
Number of levels | Number
Include animation | Checkbox
Include design | Checkbox
Include source code | Checkbox
*/

function GameDevelopment({
  handleChange,
  handleToggleChange,
  values: {
    isCompletePackage,
    basicNumberOfLevels,
    standardNumberOfLevels,
    premiumNumberOfLevels,
  },
  formErrors,
}) {
  return (
    <>
      <TableRow
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #E4E5E7',
          },
        }}
      >
        <TableCell>
          <Typography variant="body1" fontWeight="500" fontSize="16px">
            Number of levels
          </Typography>
        </TableCell>
        <TableCell>
          <TextField
            name="basicNumberOfLevels"
            placeholder="0"
            value={basicNumberOfLevels || ''}
            error={!!formErrors.basicNumberOfLevels}
            helperText={formErrors.basicNumberOfLevels}
            onChange={handleChange}
            required
            inputProps={{ maxLength: 1 }}
          />
        </TableCell>
        <TableCell>
          <TextField
            name="standardNumberOfLevels"
            placeholder="0"
            value={standardNumberOfLevels || ''}
            error={!!formErrors.standardNumberOfLevels}
            helperText={formErrors.standardNumberOfLevels}
            onChange={handleChange}
            required
            inputProps={{ maxLength: 1 }}
            disabled={!isCompletePackage}
          />
        </TableCell>
        <TableCell>
          <TextField
            name="premiumNumberOfLevels"
            placeholder="0"
            value={premiumNumberOfLevels || ''}
            error={!!formErrors.premiumNumberOfLevels}
            helperText={formErrors.premiumNumberOfLevels}
            onChange={handleChange}
            required
            inputProps={{ maxLength: 1 }}
            disabled={!isCompletePackage}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography variant="body1" fontWeight="500" fontSize="16px">
            Include animation
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="basicIncludeAnimation"
            onChange={handleToggleChange}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{
              p: 0,
            }}
            name="standardIncludeAnimation"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="premiumIncludeAnimation"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography variant="body1" fontWeight="500" fontSize="16px">
            Include design
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="basicIncludeDesign"
            onChange={handleToggleChange}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="standardIncludeDesign"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="premiumIncludeDesign"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography variant="body1" fontWeight="500" fontSize="16px">
            Include source code
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="basicSourceFile"
            onChange={handleToggleChange}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="standardSourceFile"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="premiumSourceFile"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

export default GameDevelopment;
