import { Typography, Box, Stack, Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Rating from '@mui/material/Rating';
import CircleIcon from '@mui/icons-material/Circle';
import Chip from '@mui/material/Chip';

function Gig() {
  const handleTagChip = () => {
    console.log('Test');
  };
  return (
    <Card
      sx={{
        maxWidth: 345,
        boxShadow: 'none',
        border: '1px solid',
        borderColor: 'primary.light',
      }}
    >
      <CardMedia
        component="img"
        alt=""
        height="231"
        image="https://fakeimg.pl/364x231/ebebf3/9292D4?text=Placeholder&font=bebas"
      />
      <CardContent sx={{ pb: 0 }}>
        <Stack direction="row" justifyContent="space-between">
          <Chip
            label="Crypto"
            icon={<CircleIcon sx={{ fontSize: '9px' }} />}
            onClick={handleTagChip}
            sx={{
              background: '#ECECFE',
              fontSize: '14px',
              color: 'text.primary',
            }}
          />
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ fontWeight: 'medium' }}
          >
            $ 120
          </Typography>
        </Stack>
        <Stack sx={{ mt: '16px' }}>
          <Typography
            variant="h3"
            color="text.primary"
            sx={{ fontSize: '20px' }}
          >
            I will fork crypto exchange website like pancakeswap and uniswap
          </Typography>
          <Typography variant="body2" color="text.disabled" sx={{ mt: '6px' }}>
            John Thor
          </Typography>
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between', px: '16px' }}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          sx={{ color: 'primary.stars' }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            5.0
          </Typography>
          <Stack direction="row">
            <Rating
              name="read-only"
              value={3}
              size="small"
              readOnly
              sx={{ color: 'primary.stars' }}
            />
          </Stack>
          <Typography variant="body2" color="text.secondary">
            (2,890)
          </Typography>
        </Stack>
        <Box>
          <Button variant="contained" color="primary" sx={{ fontSize: '14px' }}>
            More Detail
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}

export default Gig;
