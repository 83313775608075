import React from 'react';
import {
  Checkbox,
  TextField,
  Typography,
  TableRow,
  TableCell,
} from '@mui/material';

/*
Number of concepts included | Number
Source File | Checkbox
3D Mockup | Checkbox
Logo transparency | Checkbox
*/

function LogoDesign({
  handleChange,
  handleToggleChange,
  values: {
    isCompletePackage,
    basicConceptsIncluded,
    standardConceptsIncluded,
    premiumConceptsIncluded,
  },
  formErrors,
}) {
  return (
    <>
      <TableRow
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #E4E5E7',
          },
        }}
      >
        <TableCell>
          <Typography variant="body1" fontWeight="500" fontSize="16px">
            Concepts included
          </Typography>
        </TableCell>
        <TableCell>
          <TextField
            name="basicConceptsIncluded"
            placeholder="0"
            value={basicConceptsIncluded || ''}
            error={!!formErrors.basicConceptsIncluded}
            helperText={formErrors.basicConceptsIncluded}
            onChange={handleChange}
            required
            inputProps={{ maxLength: 1 }}
          />
        </TableCell>
        <TableCell>
          <TextField
            name="standardConceptsIncluded"
            placeholder="0"
            value={standardConceptsIncluded || ''}
            error={!!formErrors.standardConceptsIncluded}
            helperText={formErrors.standardConceptsIncluded}
            onChange={handleChange}
            required
            inputProps={{ maxLength: 1 }}
            disabled={!isCompletePackage}
          />
        </TableCell>
        <TableCell>
          <TextField
            name="premiumConceptsIncluded"
            placeholder="0"
            value={premiumConceptsIncluded || ''}
            error={!!formErrors.premiumConceptsIncluded}
            helperText={formErrors.premiumConceptsIncluded}
            onChange={handleChange}
            required
            inputProps={{ maxLength: 1 }}
            disabled={!isCompletePackage}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography variant="body1" fontWeight="500" fontSize="16px">
            Source File
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="basicSourceFile"
            onChange={handleToggleChange}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="standardSourceFile"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="premiumSourceFile"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography variant="body1" fontWeight="500" fontSize="16px">
            Logo transparency
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="basicLogoTransparency"
            onChange={handleToggleChange}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{
              p: 0,
            }}
            name="standardLogoTransparency"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="premiumLogoTransparency"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography variant="body1" fontWeight="500" fontSize="16px">
            3D Mockup
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="basicThreedMockup"
            onChange={handleToggleChange}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="standardThreedMockup"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="premiumThreedMockup"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

export default LogoDesign;
