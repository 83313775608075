import { useLocation } from 'react-router-dom';
import '@fontsource-variable/work-sans';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import './App.css';
import { theme } from './theme.js';
import LoggedHeader from './components/Header/LoggedHeader';
import MainHeader from './components/Header/MainHeader';
import Footer from './components/Footer';
import Routes from './routes';

function App({ props }) {
  let location = useLocation();
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <CssBaseline />
        {location.pathname === '/' || location.pathname === '/how-it-works' ? (
          <MainHeader />
        ) : (
          <LoggedHeader />
        )}
        <Routes />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
