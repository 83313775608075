import { Typography, Box, Stack, Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

function NewsCard() {
  return (
    <Card
      sx={{
        maxWidth: 345,
        boxShadow: 'none',
      }}
    >
      <CardMedia
        component="img"
        alt=""
        height="231"
        image="https://fakeimg.pl/364x231/ebebf3/9292D4?text=Placeholder&font=bebas"
        sx={{ borderRadius: '10px' }}
      />
      <CardContent sx={{ py: '10px', px: 0 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body1" component="div" color="text.secondary">
            SEO
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <AccessTimeIcon sx={{ fontSize: '16px' }} />
            <Typography variant="body1" component="div" color="text.secondary">
              July 5, 2020
            </Typography>
          </Stack>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <PersonOutlineIcon sx={{ fontSize: '16px' }} />
            <Typography variant="body1" component="div" color="text.secondary">
              By Diane Mccoy
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ mt: '12px' }}>
          <Typography
            variant="h3"
            color="text.primary"
            sx={{ fontSize: '20px', fontWeight: '600' }}
          >
            How to Maximize Your ROI Through Scientific SEM?
          </Typography>
          <Typography variant="body2" color="text.disabled" sx={{ mt: '6px' }}>
            Vulputate vitae pellentesque scelerisque luctus consequat mattis
            pellentesque dui odio...
          </Typography>
        </Stack>
      </CardContent>
      <CardActions sx={{ p: 0 }}>
        <Box>
          <Button variant="text" color="primary" sx={{ fontSize: '14px' }}>
            Read more <ArrowForwardIcon sx={{ fontSize: '16px' }} />
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}

export default NewsCard;
