import { useState } from 'react';
import { Box, Typography, Grid, Stack } from '@mui/material';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Pagination from '@mui/material/Pagination';
import { scroller } from 'react-scroll';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

import JobCard from '../../components/JobCard';
import { Section9 } from '../../sections/Section9';
import { fulltimeJobs } from '../../data/fulltimeJobs';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Jobs() {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  const [sort, setSort] = useState('Newest');
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    scroller.scrollTo('jobsContent', {
      smooth: true,
      offset: -300,
      duration: 500,
    });
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const getSortedJobs = () => {
    switch (sort) {
      case 'Newest':
        return [...fulltimeJobs].sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
      case 'Oldest':
        return [...fulltimeJobs].sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
      case 'Low Price':
        return [...fulltimeJobs].sort((a, b) => a.price - b.price);
      case 'High Price':
        return [...fulltimeJobs].sort((a, b) => b.price - a.price);
      default:
        return fulltimeJobs;
    }
  };
  const [jobTitle, setJobTitle] = useState('');
  const [location, setLocation] = useState('');
  const [onlineSellers, setOnlineSellers] = useState(false);

  const sortedJobs = getSortedJobs().filter((job) => {
    if (currentTabIndex === 1) {
      // Filter for "Recent Jobs" tab
      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
      return new Date(job.date) >= twoDaysAgo;
    }
    // Filter based on search query and online sellers
    const titleMatch = job.title.toLowerCase().includes(jobTitle.toLowerCase());
    const locationMatch = job.location
      .toLowerCase()
      .includes(location.toLowerCase());
    const onlineSellersMatch = onlineSellers ? job.online : true;
    return titleMatch && locationMatch && onlineSellersMatch;
  });

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: { sm: 2, md: 5 } }}>
        <Box>
          <Typography
            variant="h4"
            sx={{
              fontSize: '32px',
              fontWeight: 'medium',
              mt: '32px',
              mb: '16px',
            }}
          >
            Browse Full-time Jobs
          </Typography>
        </Box>
        <Box
          sx={{
            border: '2px solid',
            borderColor: 'primary.outline',
            p: '24px',
            borderRadius: '9px',
          }}
        >
          <Grid container spacing={2.5} alignItems="flex-end">
            <Grid item lg={3} md={6} xs={12}>
              <Typography
                variant="h3"
                sx={{ fontSize: '16px', fontWeight: 'medium', mb: '8px' }}
              >
                Job Title
              </Typography>
              <TextField
                id="job-title"
                placeholder="Enter job title"
                variant="outlined"
                fullWidth
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
              />
            </Grid>
            <Grid item lg={3} md={6} xs={12}>
              <Typography
                variant="h3"
                sx={{ fontSize: '16px', fontWeight: 'medium', mb: '8px' }}
              >
                Location
              </Typography>
              <TextField
                id="location"
                placeholder="Enter seller location"
                variant="outlined"
                fullWidth
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onlineSellers}
                    onChange={(e) => setOnlineSellers(e.target.checked)}
                  />
                }
                label="Online Sellers"
              />
            </Grid>
            <Grid item>
              <Button variant="outlined" startIcon={<FilterListOutlinedIcon />}>
                Show All Filters
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: '14px',
              fontWeight: 'medium',
              mb: '8px',
              color: 'text.secondary',
            }}
          >
            {sortedJobs.length} fulltime jobs found
          </Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select value={sort} onChange={handleSortChange} size="small">
              <MenuItem value="Newest">Sort: Newest</MenuItem>
              <MenuItem value="Oldest">Sort: Oldest</MenuItem>
              <MenuItem value="Low Price">Sort: Low Price</MenuItem>
              <MenuItem value="High Price">Sort: High Price</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            border: '2px solid',
            borderColor: 'primary.outline',
            borderRadius: '9px',
            mt: '32px',
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={currentTabIndex}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Best Matches"
                sx={{ fontSize: { xs: '14px' } }}
                {...a11yProps(0)}
              />
              <Tab
                label="Saved Jobs (0)"
                sx={{ fontSize: { xs: '14px' } }}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          {/* Best Matches Tab */}
          {currentTabIndex === 0 && (
            <Box sx={{ p: { xs: '16px', sm: '24px' } }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ pb: '16px' }}
              ></Stack>
              {sortedJobs.length !== 0 && (
                <>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'grid',
                      pb: '32px',
                    }}
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(2, 1fr)',
                      md: 'repeat(3, 1fr)',
                      lg: 'repeat(4, 1fr)',
                    }}
                    gap={4}
                    justifyContent="space-between"
                    id="jobsContent"
                  >
                    {sortedJobs
                      .slice((currentPage - 1) * 12, currentPage * 12)
                      .map((job) => (
                        <Grid item key={job.id}>
                          <JobCard {...job} />
                        </Grid>
                      ))}
                  </Box>
                  <Box
                    sx={{ p: '24px', pt: '0', display: 'flex' }}
                    justifyContent="center"
                  >
                    <Pagination
                      count={Math.ceil(sortedJobs.length / 12)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </Box>
                </>
              )}
            </Box>
          )}

          {currentTabIndex === 1 && (
            <Box sx={{ p: { xs: '16px', sm: '24px' } }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ pb: '16px' }}
              >
                No items found in your saved list.
              </Stack>
            </Box>
          )}
        </Box>
        <Section9 isFullTimeJob={true} />
      </Container>
    </>
  );
}

export default Jobs;
