import devIcon from '../assets/dev-icon.png';
import penIcon from '../assets/pen-icon.png';
import marketingIcon from '../assets/marketing-icon.png';

export const jobsCircle = [
  {
    title: 'Design & Illustration',
    links: [
      'Flat Illustration',
      '3D Design',
      'UI/UX Designer',
      'Logo Designer',
    ],
    img: penIcon,
  },
  {
    title: 'Software Development',
    links: [
      'Data Analyst',
      'Back end development',
      'Blockchain',
      'Mobile Development',
    ],
    img: devIcon,
  },
  {
    title: 'Marketing & Editing',
    links: [
      'Video Editor',
      'SEO Expert',
      'Social Media Manager',
      'Data Entry Specialist',
    ],
    img: marketingIcon,
  },
];
