import { Typography, Box, Stack, Divider, Grid } from '@mui/material';

import findTalentsImage from '../assets/find-talents.png';

export const Section2 = () => {
  return (
    <Grid
      container
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ px: { sm: 0, lg: 8 }, mt: '180px' }}
      alignItems="flex-start"
    >
      <Grid item xs={12} md={6}>
        <Divider
          textAlign="left"
          sx={{ color: 'primary.accent', fontSize: '18px', width: '200px' }}
        >
          For clients
        </Divider>
        <Typography
          variant="h2"
          color="text.primary"
          sx={{
            fontSize: '44px',
            fontWeight: '600',
            lineHeight: '48px',
            pt: '36px',
          }}
        >
          Find the most talented workers around the world
        </Typography>
        <Typography
          component="p"
          color="text.secondary"
          sx={{ fontSize: 22, pt: '16px' }}
        >
          An easy way to find workers with extraordinary talents and skills to
          help you get your job done
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ mt: { xs: '40px', md: '64px' } }}
          gap={3}
        >
          <Box sx={{ width: { xs: '100%', md: '60%' } }}>
            <Typography
              component="h6"
              sx={{
                fontSize: '18px',
                color: 'primary.main',
                fontWeight: '600',
              }}
            >
              Post a job
            </Typography>
            <Typography
              component="p"
              sx={{ fontSize: '14px', color: 'text.secondary' }}
            >
              Register then verify after that post the job. Easy way
            </Typography>
          </Box>
          <Box sx={{ width: { xs: '100%', md: '60%' } }}>
            <Typography
              component="h6"
              sx={{
                fontSize: '18px',
                color: 'primary.main',
                fontWeight: '600',
              }}
            >
              Choose a talents
            </Typography>
            <Typography
              component="p"
              sx={{ fontSize: '14px', color: 'text.secondary' }}
            >
              Choose best talents who send proposals to you
            </Typography>
          </Box>
          <Box sx={{ width: { xs: '100%', md: '60%' } }}>
            <Typography
              component="h6"
              sx={{
                fontSize: '18px',
                color: 'primary.main',
                fontWeight: '600',
              }}
            >
              Start giving work
            </Typography>
            <Typography
              component="p"
              sx={{ fontSize: '14px', color: 'text.secondary' }}
            >
              Give a job to the best talent you have chosen
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', lg: 'flex-end' },
          pt: { xs: '40px' },
        }}
      >
        <Box>
          <img
            src={findTalentsImage}
            style={{
              width: '430px',
              maxWidth: '100%',
              objectFit: 'contain',
            }}
            alt=""
          />
        </Box>
      </Grid>
    </Grid>
  );
};
