import { Typography, Box, Container, Grid, Stack } from '@mui/material';

import CryptibizLogo from '../../assets/cryptibiz.png';

const footerMenu1 = ['About Us', 'Our Impact', 'Careers', 'Contact Us'];
const footerMenu2 = ['Help & Support', 'Blog', 'Community'];
const footerMenu3 = [
  'Talents Marketplace',
  'Hiring Tutorial',
  'Talents Scout',
  'Payroll Settings',
  'Contract',
];
const footerMenu4 = ['How to apply', 'Direct Contracts', 'Find Jobs'];

export default function GuestFooter() {
  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        backgroundColor: 'primary.footer',
        py: '80px',
        mt: '3rem',
      }}
    >
      <Container maxWidth="xl">
        <Grid container direction="row">
          <Grid item xs={12} md={4} lg={5}>
            <Stack spacing={2}>
              <Typography color="primary.main" component="h5">
                <img src={CryptibizLogo} width={110} alt=""></img>
              </Typography>
              <Typography
                color="text.secondary"
                sx={{ width: { xs: '100%', md: '80%' } }}
              >
                The CryptiBiz word mark is a registered trademark owned by
                CryptiBiz PTY LTDU.S. Certificate of Registration No.
                6,000,000IP Australia Trade Mark Number 1000000
              </Typography>
              <Typography color="text.primary">Hello@CryptiBiz.com</Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            md={2}
            lg={1.5}
            sx={{ mt: { xs: '48px', md: 0 } }}
          >
            <Stack spacing="5">
              <Typography
                color="text.primary"
                component="h5"
                sx={{ fontWeight: '600', fontSize: '18px', mb: '16px' }}
              >
                Company
              </Typography>
              <Stack gap={2}>
                {footerMenu1.map((text) => (
                  <Typography
                    href="/"
                    key={text}
                    component="a"
                    sx={{ color: 'text.secondary', textDecoration: 'none' }}
                  >
                    {text}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            md={2}
            lg={1.5}
            sx={{ mt: { xs: '48px', md: 0 } }}
          >
            <Stack spacing="5">
              <Typography
                color="text.primary"
                component="h5"
                sx={{ fontWeight: '600', fontSize: '18px', mb: '16px' }}
              >
                Resources
              </Typography>
              <Stack gap={2}>
                {footerMenu2.map((text) => (
                  <Typography
                    href="/"
                    key={text}
                    component="a"
                    sx={{ color: 'text.secondary', textDecoration: 'none' }}
                  >
                    {text}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            md={2}
            lg={1.5}
            sx={{ mt: { xs: '48px', md: 0 } }}
          >
            <Stack spacing="5">
              <Typography
                color="text.primary"
                component="h5"
                sx={{ fontWeight: '600', fontSize: '18px', mb: '16px' }}
              >
                For Clients
              </Typography>
              <Stack gap={2}>
                {footerMenu3.map((text) => (
                  <Typography
                    href="/"
                    key={text}
                    component="a"
                    sx={{ color: 'text.secondary', textDecoration: 'none' }}
                  >
                    {text}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            md={2}
            lg={1.5}
            sx={{ mt: { xs: '48px', md: 0 } }}
          >
            <Stack spacing="5">
              <Typography
                color="text.primary"
                component="h5"
                sx={{ fontWeight: '600', fontSize: '18px', mb: '16px' }}
              >
                For Talents
              </Typography>
              <Stack gap={2}>
                {footerMenu4.map((text) => (
                  <Typography
                    href="/"
                    key={text}
                    component="a"
                    sx={{ color: 'text.secondary', textDecoration: 'none' }}
                  >
                    {text}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
