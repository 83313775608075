import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

export default function FeaturedCard() {
  return (
    <Card
      sx={{
        maxWidth: 300,
        boxShadow: 'none',
        border: '1px solid',
        borderColor: 'primary.light',
        borderRadius: '16px',
      }}
    >
      <CardHeader
        sx={{ pb: '0px' }}
        avatar={
          <Avatar
            sx={{
              bgcolor: '#F2F2F7',
              border: '1px solid',
              width: '68px',
              height: '68px',
              borderRadius: '16px',
              borderColor: 'primary.light',
            }}
            size="large"
            variant="rounded"
          >
            <ImageOutlinedIcon color="custom1" />
          </Avatar>
        }
      />
      <CardContent sx={{ pb: 0 }}>
        <Typography
          variant="h2"
          color="text.primary"
          sx={{ fontSize: '18px', fontWeight: '500' }}
        >
          Chrono.tech (TIME) Crypto Takes Off After a Sizable Fundraise From
          Mark Carnegie
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontSize: '14px', mt: '16px' }}
        >
          There's a new crypto investors are paying attention to today.
          Chrono.tech (CCC:TIME-USD) is seeing the spotlight after closing out
          alucrative fundraise.
        </Typography>
      </CardContent>
      <CardActions sx={{ px: '16px', mt: '16px', mb: '8px' }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ fontSize: '14px', py: '12px', px: '24px' }}
          endIcon={<CallMadeIcon />}
        >
          Read More
        </Button>
      </CardActions>
    </Card>
  );
}
