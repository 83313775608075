import React from 'react';
import {
  Checkbox,
  TextField,
  Typography,
  TableRow,
  TableCell,
} from '@mui/material';

/*
Number of pages | Number
Responsive design | Checkbox
Content upload | Checkbox
E-commerce functionality | Checkbox
*/

function WebsiteDevelopment({
  handleChange,
  handleToggleChange,
  values: {
    isCompletePackage,
    basicNumberOfPages,
    standardNumberOfPages,
    premiumNumberOfPages,
    basicResponsiveDesign,
    standardResponsiveDesign,
    premiumResponsiveDesign,
  },
  formErrors,
}) {
  return (
    <>
      <TableRow
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #E4E5E7',
          },
        }}
      >
        <TableCell>
          <Typography variant="body1" fontWeight="500" fontSize="16px">
            Number of pages
          </Typography>
        </TableCell>
        <TableCell>
          <TextField
            name="basicNumberOfPages"
            placeholder="0"
            value={basicNumberOfPages || ''}
            error={!!formErrors.basicNumberOfPages}
            helperText={formErrors.basicNumberOfPages}
            onChange={handleChange}
            required
            inputProps={{ maxLength: 1 }}
          />
        </TableCell>
        <TableCell>
          <TextField
            name="standardNumberOfPages"
            placeholder="0"
            value={standardNumberOfPages || ''}
            error={!!formErrors.standardNumberOfPages}
            helperText={formErrors.standardNumberOfPages}
            onChange={handleChange}
            required
            inputProps={{ maxLength: 1 }}
            disabled={!isCompletePackage}
          />
        </TableCell>
        <TableCell>
          <TextField
            name="premiumNumberOfPages"
            placeholder="0"
            value={premiumNumberOfPages || ''}
            error={!!formErrors.premiumNumberOfPages}
            helperText={formErrors.premiumNumberOfPages}
            onChange={handleChange}
            required
            inputProps={{ maxLength: 1 }}
            disabled={!isCompletePackage}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography variant="body1" fontWeight="500" fontSize="16px">
            Responsive design
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="basicResponsiveDesign"
            onChange={handleToggleChange}
            value={basicResponsiveDesign || false}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{
              p: 0,
            }}
            name="standardResponsiveDesign"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
            value={standardResponsiveDesign || false}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="premiumResponsiveDesign"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
            value={premiumResponsiveDesign || false}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography variant="body1" fontWeight="500" fontSize="16px">
            Content upload
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="basicContentUpload"
            onChange={handleToggleChange}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="standardContentUpload"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="premiumContentUpload"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography variant="body1" fontWeight="500" fontSize="16px">
            E-commerce functionality
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="basicEcommerceFunctionality"
            onChange={handleToggleChange}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="standardEcommerceFunctionality"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
        <TableCell align="center">
          <Checkbox
            sx={{ p: 0 }}
            name="premiumEcommerceFunctionality"
            onChange={handleToggleChange}
            disabled={!isCompletePackage}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

export default WebsiteDevelopment;
