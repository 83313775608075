import { Typography, Grid } from '@mui/material';

import { JobCircle } from '../components/JobCircle';
import { jobsCircle } from '../data/jobsCircle';

export const Section9 = ({ sx, isTask, isFullTimeJob }) => {
  return (
    <Grid
      container
      direction={{ xs: 'row', sm: 'column' }}
      sx={{ px: { sm: 0, lg: 8 }, mt: '100px', mb: '100px', ...sx }}
      justifyContent="center"
    >
      <Grid item>
        <Typography
          variant="h2"
          color="text.primary"
          sx={{
            fontSize: '44px',
            fontWeight: 'medium',
            lineHeight: '48px',
            pt: '36px',
            textAlign: 'center',
          }}
        >
          Various types of{' '}
          {isTask ? 'tasks' : isFullTimeJob ? 'Fulltime Jobs' : 'jobs'} you can
          find
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ fontSize: '22px', pt: '16px', textAlign: 'center' }}
        >
          Dozens of types of{' '}
          {isTask ? 'tasks' : isFullTimeJob ? 'Fulltime Jobs' : 'jobs'} that you
          can find here, of course, not only in the IT field
        </Typography>
      </Grid>
      <Grid
        container
        direction={{ xs: 'column', sm: 'row' }}
        sx={{ mt: '90px' }}
        justifyContent="center"
        alignItems="center"
        gap={8}
      >
        {jobsCircle.map((data, index) => (
          <Grid item lg={2} key={index}>
            <JobCircle links={data.links} title={data.title} img={data.img} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
