import Typography from '@mui/material/Typography';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
} from '@mui/material';
import { scroller } from 'react-scroll';
import { useState } from 'react';

const helpList = [
  'After a client purchases your project, they will be required to answer questions you define before the contract officially starts.',
  'The due date for your project is defined by the number of days to deliver, starting from when the client submits these requirements.',
];

export function Step4({
  handleNext,
  handleChange,
  handleToggleChange,
  handleBack,
  values: { taskRequirements, isRequirementsMandatory },
  formErrors,
}) {
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const handleCheckbox = (e, c) => {
    setCheckboxChecked(!checkboxChecked);
    handleToggleChange(e);
  };
  const isValid =
    (taskRequirements.length > 0 && !formErrors.taskRequirements) ||
    !checkboxChecked;

  function handleScrollUp() {
    scroller.scrollTo('stepTitle', {
      smooth: true,
      offset: -300,
      duration: 500,
    });
  }

  function handleNextAndScroll(e) {
    handleNext(e);
    handleScrollUp();
  }

  function handleBackAndScroll(e) {
    handleBack(e);
    handleScrollUp();
  }
  return (
    <Stack>
      {/* Step Title */}
      <Typography
        variant="h3"
        sx={{ fontSize: '24px', fontWeight: 'regular', mb: '24px', mt: '32px' }}
        id="stepTitle"
      >
        Requirements for the client
      </Typography>
      <Grid container justifyContent="space-between">
        <Grid item lg sx={{ mr: '56px', width: '100%' }}>
          <Stack spacing={4}>
            <Stack spacing={1}>
              <Typography
                variant="h3"
                sx={{ fontSize: '22px', fontWeight: '500' }}
              >
                Tell the client what you need to get started
              </Typography>
              <Typography
                variant="h3"
                sx={{ fontSize: '16px', color: 'text.secondary' }}
              >
                Structure your buyer instructions
              </Typography>
              <Box
                sx={{
                  bgcolor: '#F5F5F5',
                  display: 'flex',
                  flexDirection: 'column',
                  width: { xs: '100%', lg: '90%' },
                  gap: '12px',
                  borderRadius: '4px',
                  border: '1px solid #E4E5E7',
                  p: '20px',
                  mt: '24px !important',
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: '20px',
                    color: 'text.primary',
                  }}
                >
                  Requirements
                </Typography>
                <TextField
                  name="taskRequirements"
                  multiline
                  maxRows={5}
                  placeholder="Request necessary details such as dimensions, brand guidelines, and more."
                  inputProps={{ maxLength: 250 }}
                  value={taskRequirements || ''}
                  error={!!formErrors.taskRequirements}
                  helperText={
                    formErrors.taskRequirements || '250 characters max'
                  }
                  onChange={handleChange}
                  required
                  sx={{
                    '& .MuiInputBase-root': {
                      bgcolor: 'white',
                    },
                  }}
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isRequirementsMandatory"
                        onChange={handleCheckbox}
                        checked={isRequirementsMandatory}
                      />
                    }
                    label="Mandatory requirement"
                  />
                </FormGroup>
              </Box>
            </Stack>
          </Stack>
        </Grid>
        <Grid item sx={{ mt: { xs: '24px', lg: 0 } }}>
          <Card
            variant="outlined"
            sx={{ px: '32px', py: '24px', maxWidth: '310px' }}
          >
            <CardHeader
              title="Define what you need from your client upfront"
              sx={{
                p: 0,
                '& .MuiTypography-root': {
                  fontSize: '20px',
                  fontWeight: 'medium',
                },
              }}
            />
            <CardContent
              sx={{
                p: 0,
                pb: 0,
                '&:last-child': {
                  pb: 0,
                },
              }}
            >
              <List
                sx={{
                  listStyleType: 'disc',
                  pl: '16px',
                  '& .MuiListItem-root': {
                    display: 'list-item',
                  },
                }}
              >
                {helpList.map((label, index) => (
                  <ListItem disablePadding key={index}>
                    <ListItemText
                      sx={{ color: 'primary.main', fontSize: '14px' }}
                    >
                      {label}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Buttons */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2,
          justifyContent: 'flex-end',
        }}
      >
        <Button color="inherit" onClick={handleBackAndScroll} sx={{ mr: 1 }}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={isValid ? handleNextAndScroll : null}
          disabled={!isValid}
        >
          Save & Continue
        </Button>
      </Box>
    </Stack>
  );
}
