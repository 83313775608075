import React from 'react';
import { TextField, Typography, TableRow, TableCell } from '@mui/material';

/*
Number of concepts included | Number
*/

function GameArt({
  handleChange,
  values: {
    isCompletePackage,
    basicConceptsIncluded,
    standardConceptsIncluded,
    premiumConceptsIncluded,
  },
  formErrors,
}) {
  return (
    <>
      <TableRow
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #E4E5E7',
          },
        }}
      >
        <TableCell>
          <Typography variant="body1" fontWeight="500" fontSize="16px">
            Concepts included
          </Typography>
        </TableCell>
        <TableCell>
          <TextField
            name="basicConceptsIncluded"
            placeholder="0"
            value={basicConceptsIncluded || ''}
            error={!!formErrors.basicConceptsIncluded}
            helperText={formErrors.basicConceptsIncluded}
            onChange={handleChange}
            required
            inputProps={{ maxLength: 1 }}
          />
        </TableCell>
        <TableCell>
          <TextField
            name="standardConceptsIncluded"
            placeholder="0"
            value={standardConceptsIncluded || ''}
            error={!!formErrors.standardConceptsIncluded}
            helperText={formErrors.standardConceptsIncluded}
            onChange={handleChange}
            required
            inputProps={{ maxLength: 1 }}
            disabled={!isCompletePackage}
          />
        </TableCell>
        <TableCell>
          <TextField
            name="premiumConceptsIncluded"
            placeholder="0"
            value={premiumConceptsIncluded || ''}
            error={!!formErrors.premiumConceptsIncluded}
            helperText={formErrors.premiumConceptsIncluded}
            onChange={handleChange}
            required
            inputProps={{ maxLength: 1 }}
            disabled={!isCompletePackage}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

export default GameArt;
