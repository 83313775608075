import { Typography, Box, Stack, Button, Grid, alpha } from '@mui/material';

import jobsSearchImage from '../assets/jobs-search.png';

export const Section3 = () => {
  return (
    <Grid
      container
      direction={{ xs: 'column', sm: 'row' }}
      sx={{
        background: '#1B1E2C',
        p: '40px',
        mt: '180px',
        borderRadius: '32px',
      }}
      alignItems="flex-start"
    >
      <Grid item xs={12} md={6}>
        <Typography
          textAlign="left"
          sx={{ color: alpha('#fff', 0.5), fontSize: '18px', width: '200px' }}
        >
          For talents
        </Typography>
        <Typography
          variant="h2"
          color="common.white"
          sx={{
            fontSize: '44px',
            fontWeight: '600',
            lineHeight: '48px',
            pt: '16px',
          }}
        >
          Search thousands of jobs available here
        </Typography>
        <Typography
          component="p"
          color="text.secondary"
          sx={{ fontSize: 22, pt: '16px' }}
        >
          Only in 3 easy ways you can apply for thousands of jobs that posted
          everyday
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ mt: { xs: '40px', md: '64px' } }}
          spacing={1}
        >
          <Box sx={{ width: { xs: '100%', md: '60%' } }}>
            <Typography
              component="h6"
              sx={{
                fontSize: '18px',
                color: 'primary.main',
                fontWeight: '600',
                mb: '8px',
              }}
            >
              Start Working
            </Typography>
            <Typography
              component="p"
              sx={{ fontSize: '14px', color: 'text.secondary' }}
            >
              Register then verify after that post the job. Easy way
            </Typography>
          </Box>
          <Box sx={{ width: { xs: '100%', md: '60%' } }}>
            <Typography
              component="h6"
              sx={{
                fontSize: '18px',
                color: 'primary.main',
                fontWeight: '600',
                mb: '8px',
              }}
            >
              Submit proposal
            </Typography>
            <Typography
              component="p"
              sx={{ fontSize: '14px', color: 'text.secondary' }}
            >
              Find available jobs and submit proposals
            </Typography>
          </Box>
          <Box sx={{ width: { xs: '100%', md: '60%' } }}>
            <Typography
              component="h6"
              sx={{
                fontSize: '18px',
                color: 'primary.main',
                fontWeight: '600',
                mb: '8px',
              }}
            >
              Register
            </Typography>
            <Typography
              component="p"
              sx={{ fontSize: '14px', color: 'text.secondary' }}
            >
              Register with a few easy and safe steps
            </Typography>
          </Box>
        </Stack>
        <Button variant="contained" sx={{ px: '16px', py: '6px', mt: '24px' }}>
          Get Started
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', lg: 'flex-end' },
          pt: { xs: '40px' },
        }}
      >
        <Box>
          <img
            src={jobsSearchImage}
            style={{
              width: '383px',
              maxWidth: '100%',
              objectFit: 'contain',
            }}
            alt=""
          />
        </Box>
      </Grid>
    </Grid>
  );
};
