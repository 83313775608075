const isText = RegExp(/^[\s\S]+$/);
const isTitle = RegExp(/^(?:[\w, -]+){5,}$/);
const isTag = RegExp(/(?:\b\w+\b|\s+)(?:,|$)/g);
const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
const isPhone = RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4,6})$/);
const isZip = RegExp(/^[0-9]{5}([- /]?[0-9]{4})?$/);
const isNumber = RegExp(/^\d+$/);
const isUrl = RegExp(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i);
const isBigText = '';

export default function formValidation(name, value, schema) {
  const { validate, minLength, maxLength, isPrice, ignoreZero } = schema[name];
  let error = '';

  if (minLength && value.length < minLength)
    error = `Minimum ${minLength} characters is required.`;
  else if (maxLength && value.length > maxLength)
    error = `Maximum length of ${maxLength} exceeded!`;
  else if (isPrice && value < 5) error = `Price have to be geater than 5$`;
  else if (ignoreZero && parseInt(value) === 0) error = `Value can't be zero`;
  if (!validate) return;

  switch (validate) {
    case 'text':
      if (!isText.test(value)) error = 'This field accept text only.';
      break;

    case 'title':
      if (!isTitle.test(value)) error = 'This field accept text only.';
      break;

    case 'tag':
      if (!isTag.test(value)) error = 'This field accept text only.';
      break;

    case 'number':
      if (!isNumber.test(value)) error = 'This field accept numbers only.';
      break;

    case 'email':
      if (!isEmail.test(value)) error = 'Please enter a valid email';
      break;

    case 'phone':
      if (!isPhone.test(value))
        error = 'Please enter a valid phone number. i.e: xxx-xxx-xxxx';
      break;

    case 'zip':
      if (!isZip.test(value)) error = 'Please enter a valid zip code';
      break;

    case 'checkbox':
      if (!value) error = 'Please select a value';
      break;

    case 'url':
      if (!isUrl.test(value)) error = 'Error while getting the URL';
      break;

    case 'bigText':
      if (isBigText) error = 'Please enter a valid text';
      break;

    default:
      break;
  }

  return error;
}
