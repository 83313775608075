import { Typography, Button, Grid } from '@mui/material';

import digitalMarketingIcon from '../assets/digital-marketing-icon.png';
import designIcon from '../assets/design-icon.png';
import businessIcon from '../assets/business-icon.png';
import programmingIcon from '../assets/programming-icon.png';

export const Section4 = () => {
  return (
    <Grid
      container
      direction={{ xs: 'row', sm: 'column' }}
      sx={{ px: { sm: 0, lg: 8 }, mt: '180px', textAlign: 'center' }}
    >
      <Grid item>
        <Typography
          variant="h2"
          color="text.primary"
          sx={{
            fontSize: '44px',
            fontWeight: 'medium',
            lineHeight: '48px',
            pt: '36px',
          }}
        >
          Trending Categories
        </Typography>
        <Typography
          component="p"
          color="text.secondary"
          sx={{ fontSize: '22px', pt: '16px', textAlign: 'center' }}
        >
          The most trending categories
        </Typography>
      </Grid>
      <Grid
        container
        direction={{ xs: 'column', sm: 'row' }}
        sx={{ mt: '40px' }}
        justifyContent="center"
        gap={3}
      >
        <Grid
          item
          lg={2}
          sx={{
            border: '1px solid',
            borderColor: 'primary.light',
            borderRadius: '16px',
            py: '16px',
            px: '16px',
          }}
        >
          <img
            src={digitalMarketingIcon}
            style={{
              width: '52px',
              maxWidth: '100%',
              objectFit: 'contain',
            }}
            alt=""
          />
          <Typography
            component="h6"
            sx={{
              fontSize: '24px',
              color: 'text.primary',
              fontWeight: 'medium',
              mt: '16px',
            }}
          >
            Marketing
          </Typography>
          <Typography
            component="p"
            sx={{ fontSize: '16px', color: 'text.secondary', mt: '4px' }}
          >
            All About Marketing
          </Typography>
        </Grid>
        <Grid
          item
          lg={2}
          sx={{
            border: '1px solid',
            borderColor: 'primary.light',
            borderRadius: '16px',
            py: '16px',
            px: '16px',
          }}
        >
          <img
            src={designIcon}
            style={{
              width: '52px',
              maxWidth: '100%',
              objectFit: 'contain',
            }}
            alt=""
          />
          <Typography
            component="h6"
            sx={{
              fontSize: '24px',
              color: 'text.primary',
              fontWeight: 'medium',
              mt: '16px',
            }}
          >
            Design
          </Typography>
          <Typography
            component="p"
            sx={{ fontSize: '16px', color: 'text.secondary', mt: '4px' }}
          >
            All About Desgin
          </Typography>
        </Grid>
        <Grid
          item
          lg={2}
          sx={{
            border: '1px solid',
            borderColor: 'primary.light',
            borderRadius: '16px',
            py: '16px',
            px: '16px',
          }}
        >
          <img
            src={programmingIcon}
            style={{
              width: '52px',
              maxWidth: '100%',
              objectFit: 'contain',
            }}
            alt=""
          />
          <Typography
            component="h6"
            sx={{
              fontSize: '24px',
              color: 'text.primary',
              fontWeight: 'medium',
              mt: '16px',
            }}
          >
            Programing
          </Typography>
          <Typography
            component="p"
            sx={{ fontSize: '16px', color: 'text.secondary', mt: '4px' }}
          >
            Create Web & Apps
          </Typography>
        </Grid>
        <Grid
          item
          lg={2}
          sx={{
            border: '1px solid',
            borderColor: 'primary.light',
            borderRadius: '16px',
            py: '16px',
            px: '16px',
          }}
        >
          <img
            src={businessIcon}
            style={{
              width: '52px',
              maxWidth: '100%',
              objectFit: 'contain',
            }}
            alt=""
          />
          <Typography
            component="h6"
            sx={{
              fontSize: '24px',
              color: 'text.primary',
              fontWeight: 'medium',
              mt: '16px',
            }}
          >
            Business
          </Typography>
          <Typography
            component="p"
            sx={{ fontSize: '16px', color: 'text.secondary', mt: '4px' }}
          >
            All About Business
          </Typography>
        </Grid>
      </Grid>
      <Grid item justifyContent="center" xs>
        <Button
          variant="outlined"
          color="custom"
          sx={{ px: '16px', py: '8px', mt: '32px', color: 'primary.main' }}
        >
          See All Categories
        </Button>
      </Grid>
    </Grid>
  );
};
