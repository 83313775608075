import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Button, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { useTheme, styled, alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import useMediaQuery from '@mui/material/useMediaQuery';
import Badge from '@mui/material/Badge';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';

import CryptibizLogo from '../../assets/cryptibiz.png';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'transparent',
  border: '2px solid',
  borderColor: theme.palette.primary.outline,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderRight: 0,
  color: theme.palette.primary.outline,
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    borderTopRightRadius: '9px',
    borderBottomRightRadius: '9px',
    borderRight: '2px solid',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create('width'),
    width: '60ch',
    color: '#B2B2CA',
    '&::placeholder': {
      opacity: 1,
    },
    [theme.breakpoints.only('lg')]: {
      width: '40ch',
    },
    [theme.breakpoints.only('md')]: {
      width: '30ch',
    },
    [theme.breakpoints.only('sm')]: {
      width: '20ch',
    },
  },
}));

const SearchIconWrapper = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  border: '2px solid',
  borderColor: theme.palette.primary.main,
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  '&:hover': {
    background: theme.palette.primary.dark,
    borderColor: theme.palette.primary.dark,
  },
}));

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
});

const pages = [
  { tasks: 'Search Tasks' },
  { jobs: 'Search Jobs' },
  { 'how-it-works': 'How it works' },
  { blog: 'Blog' },
];

export default function LoggedHeader() {
  const theme = useTheme();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMessages = () => {};
  const handleNotification = () => {};
  const handleFavorits = () => {};
  const handleAvatar = () => {};

  return (
    <AppBar
      position="sticky"
      sx={{
        boxShadow: 0,
        backgroundColor: 'white',
        py: '8px',
        // borderTop: '1px solid',
        borderBottom: '1px solid',
        borderColor: 'primary.outline',
      }}
    >
      <Container maxWidth="xl">
        <StyledToolbar disableGutters>
          {/* Mobile SideBar & Button */}
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              sx={{ mr: 1, display: { xs: 'flex', md: 'none' } }}
              onClick={handleSidebarToggle}
            >
              <MenuIcon />
            </IconButton>
            <Link href="/">
              <img
                src={CryptibizLogo}
                width={120}
                alt=""
                style={{ display: 'block' }}
              />
            </Link>
            <Stack
              direction="row"
              gap={8}
              alignItems={'center'}
              sx={{
                display: { xs: 'none', sm: 'block' },
                ml: { xs: '8px', md: '64px' },
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Search>
                  <StyledInputBase
                    id="search"
                    placeholder={
                      isSmallScreen
                        ? 'Find Services'
                        : 'What service are you looking for today?'
                    }
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </Search>
                <SearchIconWrapper
                  sx={{
                    display: { xs: 'none', sm: 'flex' },
                    alignItems: 'center',
                    px: '12px',
                  }}
                >
                  <SearchIcon sx={{ color: 'common.white' }} />
                </SearchIconWrapper>
              </Box>
            </Stack>
          </Stack>
          <Drawer
            anchor={'left'}
            open={isSidebarOpen}
            onClose={handleSidebarToggle}
          >
            <Box width={'300px'}>
              <List>
                {pages.map((text, index) => (
                  <ListItem key={Object.keys(text)[0]} disablePadding>
                    <ListItemButton href={Object.keys(text)[0]}>
                      {Object.values(text)[0]}
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Divider />
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText primary={'Register'} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Drawer>

          <Stack direction="row" alignItems={'center'} gap={2}>
            <Tooltip title="Notifications">
              <Link href="/">
                <Badge
                  badgeContent={0}
                  variant="dot"
                  color="_primary"
                  sx={{ display: { xs: 'none', md: 'block' } }}
                  onClick={handleNotification}
                >
                  <NotificationsOutlinedIcon
                    sx={{ color: 'text.primary', display: 'block' }}
                  />
                </Badge>
              </Link>
            </Tooltip>
            <Tooltip title="Messages">
              <Link href="/">
                <Badge
                  badgeContent={1}
                  variant="dot"
                  color="_primary"
                  sx={{ display: { xs: 'none', md: 'block' } }}
                  onClick={handleMessages}
                >
                  <EmailOutlinedIcon
                    sx={{ color: 'text.primary', display: 'block' }}
                  />
                </Badge>
              </Link>
            </Tooltip>

            <Tooltip title="Wishlist">
              <Link href="/">
                <Badge
                  badgeContent={0}
                  variant="dot"
                  color="_primary"
                  sx={{ display: { xs: 'none', md: 'block' } }}
                  onClick={handleFavorits}
                >
                  <FavoriteBorderOutlinedIcon
                    sx={{ color: 'text.primary', display: 'block' }}
                  />
                </Badge>
              </Link>
            </Tooltip>
            <Link sx={{ textDecoration: 'none' }} href="/">
              <Typography
                sx={{
                  color: theme.palette.text.primary,
                  fontWeight: '600',
                  display: { xs: 'none', md: 'block' },
                }}
              >
                Orders
              </Typography>
            </Link>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
              onClick={handleAvatar}
            >
              <Tooltip title="Anass Soukrat">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt="Anass Soukrat"
                    src="https://thispersondoesnotexist.com/"
                  />
                </IconButton>
              </Tooltip>
              <StyledMenu
                sx={{ mt: '45px', minWidth: '180px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleCloseUserMenu} disableRipple>
                  Profile
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu} disableRipple>
                  Dashboard
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleCloseUserMenu} disableRipple>
                  Settings
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu} disableRipple>
                  Billing and Payments
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleCloseUserMenu} disableRipple>
                  Help & support
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu} disableRipple>
                  Logout
                </MenuItem>
              </StyledMenu>
            </StyledBadge>
          </Stack>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
}
