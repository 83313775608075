import { Box, Stack, Typography, Grid } from '@mui/material';
import Container from '@mui/material/Container';

function HowItWorks() {
  return (
    <>
      <Stack
        sx={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: '#E3E9FB',
          height: '300px',
          mt: '-80px',
        }}
      >
        <Box
          sx={{ width: '32px', height: '2px', bgcolor: 'primary.accent' }}
        ></Box>
        <Typography variant="h2" sx={{ fontSize: '40px', fontWeight: 'bold' }}>
          HOW IT WORKS
        </Typography>
      </Stack>
      <Container maxWidth="xl" sx={{ mt: { sm: 2, md: 5 } }}>
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: '600',
              fontSize: '32px',
              mt: { xs: '24px', md: '0px' },
            }}
          >
            Get Started with 3 Easy Steps
          </Typography>
          <Grid
            container
            justifyContent="space-evenly"
            sx={{ mt: '64px', width: '70%' }}
          >
            <Grid item sx={{ width: '300px' }}>
              <Stack sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: '24px',
                    fontWeight: '600',
                    mb: '8px',
                    color: 'primary.main',
                    display: { xs: 'block', lg: 'none' },
                  }}
                >
                  01
                </Typography>
                <Typography
                  variant="h2"
                  sx={{ fontSize: '24px', fontWeight: '600', mb: '16px' }}
                >
                  Post Your Job
                </Typography>
                <Typography variant="body1">
                  Post your job in a few simple steps and invite interesting
                  professionals to respond.
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              sx={{ display: { xs: 'none', lg: 'block' }, px: '24px' }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: '32px',
                    fontWeight: '600',
                    color: 'primary.main',
                  }}
                >
                  01
                </Typography>
                <Box
                  sx={{
                    borderLeft: '2px solid #EEE',
                    height: '160px',
                  }}
                />
              </Stack>
            </Grid>
            <Grid sx={{ mt: { xs: '16px', md: 0 } }}>
              <Box>
                <img
                  component="img"
                  alt=""
                  width="300px"
                  src="https://fakeimg.pl/300x168/ebebf3/9292D4?text=PH&font=bebas"
                  style={{ borderRadius: '8px' }}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            sx={{
              mt: '64px',
              width: '70%',
              display: { xs: 'none', lg: 'flex' },
            }}
          >
            <Grid item sx={{ mt: { xs: '16px', md: 0 } }}>
              <Box>
                <img
                  component="img"
                  alt=""
                  width="300px"
                  src="https://fakeimg.pl/300x168/ebebf3/9292D4?text=PH&font=bebas"
                  style={{ borderRadius: '8px' }}
                />
              </Box>
            </Grid>
            <Grid
              item
              sx={{ display: { xs: 'none', lg: 'block' }, px: '24px' }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: '32px',
                    fontWeight: '600',
                    color: 'primary.main',
                  }}
                >
                  02
                </Typography>
                <Box
                  sx={{
                    borderLeft: '2px solid #EEE',
                    height: '160px',
                  }}
                />
              </Stack>
            </Grid>
            <Grid item sx={{ width: '300px' }}>
              <Stack sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                <Typography
                  variant="h2"
                  sx={{ fontSize: '24px', fontWeight: '600', mb: '16px' }}
                >
                  Discuss Your Project
                </Typography>
                <Typography variant="body1">
                  Professionals respond Interested professionals usually respond
                  within 24 hours of posting your job.
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          {/* LG Section */}
          <Grid
            container
            justifyContent="space-evenly"
            sx={{
              mt: '64px',
              width: '70%',
              display: { xs: 'flex', lg: 'none' },
            }}
          >
            <Grid item sx={{ width: '300px' }}>
              <Stack sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: '24px',
                    fontWeight: '600',
                    mb: '8px',
                    color: 'primary.main',
                    display: { xs: 'block', lg: 'none' },
                  }}
                >
                  02
                </Typography>
                <Typography
                  variant="h2"
                  sx={{ fontSize: '24px', fontWeight: '600', mb: '16px' }}
                >
                  Discuss Your Project
                </Typography>
                <Typography variant="body1">
                  Professionals respond Interested professionals usually respond
                  within 24 hours of posting your job.
                </Typography>
              </Stack>
            </Grid>
            <Grid item sx={{ mt: { xs: '16px', md: 0 } }}>
              <Box>
                <img
                  component="img"
                  alt=""
                  width="300px"
                  src="https://fakeimg.pl/300x168/ebebf3/9292D4?text=PH&font=bebas"
                  style={{ borderRadius: '8px' }}
                />
              </Box>
            </Grid>
            <Grid
              item
              sx={{ display: { xs: 'none', lg: 'block' }, px: '24px' }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: '32px',
                    fontWeight: '600',
                    color: 'primary.main',
                  }}
                >
                  02
                </Typography>
                <Box
                  sx={{
                    borderLeft: '2px solid #EEE',
                    height: '160px',
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            sx={{ mt: '64px', width: '70%' }}
          >
            <Grid item sx={{ width: '300px' }}>
              <Stack sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: '24px',
                    fontWeight: '600',
                    mb: '8px',
                    color: 'primary.main',
                    display: { xs: 'block', lg: 'none' },
                  }}
                >
                  03
                </Typography>
                <Typography
                  variant="h2"
                  sx={{ fontSize: '24px', fontWeight: '600', mb: '16px' }}
                >
                  Finalize Your Order
                </Typography>
                <Typography variant="body1">
                  Select and get in touch Compare profiles and add your favorite
                  professionals to your selection to discuss your job and
                  request a quote.
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              sx={{ display: { xs: 'none', lg: 'block' }, px: '24px' }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: '32px',
                    fontWeight: '600',
                    color: 'primary.main',
                  }}
                >
                  03
                </Typography>
                <Box
                  sx={{
                    borderLeft: '2px solid #EEE',
                    height: '160px',
                  }}
                />
              </Stack>
            </Grid>
            <Grid sx={{ mt: { xs: '16px', md: 0 } }}>
              <Box>
                <img
                  component="img"
                  alt=""
                  width="300px"
                  src="https://fakeimg.pl/300x168/ebebf3/9292D4?text=PH&font=bebas"
                  style={{ borderRadius: '8px' }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}

export default HowItWorks;
