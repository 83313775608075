import Typography from '@mui/material/Typography';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Switch,
  Button,
  Box,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { ErrorOutline } from '@mui/icons-material';
import { useState } from 'react';
import { scroller } from 'react-scroll';

import GameArt from '../FeaturesForms/GameArt';
import LogoDesign from '../FeaturesForms/LogoDesign';
import WebsiteDevelopment from '../FeaturesForms/WebsiteDevelopment';
import GameDevelopment from '../FeaturesForms/GameDevelopment';

export function Step2({
  handleNext,
  handleBack,
  handleChange,
  handleToggleChange,
  values: {
    basicTitle,
    standardTitle,
    premiumTitle,
    basicDescription,
    standardDescription,
    premiumDescription,
    basicDelivery,
    standardDelivery,
    premiumDelivery,
    basicRevisions,
    standardRevisions,
    premiumRevisions,
    basicPrice,
    standardPrice,
    premiumPrice,
    isCompletePackage,
    basicConceptsIncluded,
    standardConceptsIncluded,
    premiumConceptsIncluded,
    basicNumberOfPages,
    standardNumberOfPages,
    premiumNumberOfPages,
    basicNumberOfLevels,
    standardNumberOfLevels,
    premiumNumberOfLevels,
    subcategory,
    basicResponsiveDesign,
    standardResponsiveDesign,
    premiumResponsiveDesign,
  },
  formErrors,
}) {
  const subcategoryForm = () => {
    switch (subcategory) {
      case 'Logo Design':
        return {
          element: (
            <LogoDesign
              values={{
                isCompletePackage: isCompletePackage,
                basicConceptsIncluded: basicConceptsIncluded,
                standardConceptsIncluded: standardConceptsIncluded,
                premiumConceptsIncluded: premiumConceptsIncluded,
              }}
              handleChange={handleChange}
              handleToggleChange={handleToggleChange}
              formErrors={formErrors}
            />
          ),
          key: 'logoDesign',
        };
      case 'Game Art':
        return {
          element: (
            <GameArt
              values={{
                isCompletePackage: isCompletePackage,
                basicConceptsIncluded: basicConceptsIncluded,
                standardConceptsIncluded: standardConceptsIncluded,
                premiumConceptsIncluded: premiumConceptsIncluded,
              }}
              handleChange={handleChange}
              formErrors={formErrors}
            />
          ),
          key: 'gameArt',
        };
      case 'Website Development':
        return {
          element: (
            <WebsiteDevelopment
              values={{
                isCompletePackage: isCompletePackage,
                basicNumberOfPages: basicNumberOfPages,
                standardNumberOfPages: standardNumberOfPages,
                premiumNumberOfPages: premiumNumberOfPages,
                basicResponsiveDesign: basicResponsiveDesign,
                standardResponsiveDesign: standardResponsiveDesign,
                premiumResponsiveDesign: premiumResponsiveDesign,
              }}
              handleChange={handleChange}
              handleToggleChange={handleToggleChange}
              formErrors={formErrors}
            />
          ),
          key: 'websiteDevelopment',
        };
      case 'Game Development':
        return {
          element: (
            <GameDevelopment
              values={{
                isCompletePackage: isCompletePackage,
                basicNumberOfLevels: basicNumberOfLevels,
                standardNumberOfLevels: standardNumberOfLevels,
                premiumNumberOfLevels: premiumNumberOfLevels,
              }}
              handleChange={handleChange}
              handleToggleChange={handleToggleChange}
              formErrors={formErrors}
            />
          ),
          key: 'gameDevelopment',
        };
      default:
        return null;
    }
  };

  const subcategoryData = subcategoryForm();
  const isGameArt = subcategoryData.key === 'gameArt';
  const isLogoDesign = subcategoryData.key === 'logoDesign';
  const isGameDevelopment = subcategoryData.key === 'gameDevelopment';
  const isWebsiteDevelopment = subcategoryData.key === 'websiteDevelopment';

  const isValid =
    basicTitle.length > 0 &&
    !formErrors.basicTitle &&
    (!isCompletePackage ||
      (standardTitle.length > 0 &&
        !formErrors.standardTitle &&
        premiumTitle.length > 0 &&
        !formErrors.premiumTitle)) &&
    basicDescription.length > 0 &&
    !formErrors.basicDescription &&
    (!isCompletePackage ||
      (standardDescription.length > 0 &&
        !formErrors.standardDescription &&
        premiumDescription.length > 0 &&
        !formErrors.premiumDescription)) &&
    basicDelivery.length > 0 &&
    !formErrors.basicDelivery &&
    (!isCompletePackage ||
      (standardDelivery.length > 0 &&
        !formErrors.standardDelivery &&
        premiumDelivery.length > 0 &&
        !formErrors.premiumDelivery)) &&
    basicRevisions.length > 0 &&
    !formErrors.basicRevisions &&
    (!isCompletePackage ||
      (standardRevisions.length > 0 &&
        !formErrors.standardRevisions &&
        premiumRevisions.length > 0 &&
        !formErrors.premiumRevisions)) &&
    basicPrice.length > 0 &&
    !formErrors.basicPrice &&
    (!isCompletePackage ||
      (standardPrice.length > 0 &&
        !formErrors.standardPrice &&
        premiumPrice.length > 0 &&
        !formErrors.premiumPrice)) &&
    ((!isGameArt && !isLogoDesign) ||
      (basicConceptsIncluded.length > 0 &&
        !formErrors.basicConceptsIncluded &&
        (!isCompletePackage ||
          (standardConceptsIncluded.length > 0 &&
            !formErrors.standardConceptsIncluded &&
            premiumConceptsIncluded.length > 0 &&
            !formErrors.premiumConceptsIncluded)))) &&
    (!isWebsiteDevelopment ||
      (basicNumberOfPages.length > 0 &&
        !formErrors.basicNumberOfPages &&
        (!isCompletePackage ||
          (standardNumberOfPages.length > 0 &&
            !formErrors.standardNumberOfPages &&
            premiumNumberOfPages.length > 0 &&
            !formErrors.premiumNumberOfPages)))) &&
    (!isGameDevelopment ||
      (basicNumberOfLevels.length > 0 &&
        !formErrors.basicNumberOfLevels &&
        (!isCompletePackage ||
          (standardNumberOfLevels.length > 0 &&
            !formErrors.standardNumberOfLevels &&
            premiumNumberOfLevels.length > 0 &&
            !formErrors.premiumNumberOfLevels))));

  const [isPKState, setIsPKState] = useState(isCompletePackage);

  const handleToggle = (e) => {
    handleToggleChange(e);
    setIsPKState(!isPKState);
  };

  function handleScrollUp() {
    scroller.scrollTo('stepTitle', {
      smooth: true,
      offset: -300,
      duration: 500,
    });
  }

  function handleNextAndScroll(e) {
    handleNext(e);
    handleScrollUp();
  }

  function handleBackAndScroll(e) {
    handleBack(e);
    handleScrollUp();
  }

  return (
    <Stack>
      {/* Step Title */}
      <Typography
        variant="h3"
        sx={{ fontSize: '24px', fontWeight: 'regular', mb: '24px', mt: '32px' }}
        id="stepTitle"
      >
        Price & scope
      </Typography>
      <Grid container justifyContent="space-between">
        <Grid item lg sx={{ mr: '56px', width: '100%' }}>
          <Stack spacing={4}>
            <Stack direction="row" justifyContent="space-between">
              <Stack spacing={1}>
                <Typography
                  variant="h3"
                  sx={{ fontSize: '22px', fontWeight: '500' }}
                >
                  Create pricing tiers
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ fontSize: '16px', color: 'text.secondary' }}
                >
                  Customize your project with 1 or 3 pricing tiers
                </Typography>
              </Stack>
              <Switch
                name="isCompletePackage"
                onChange={handleToggle}
                checked={isCompletePackage}
              />
            </Stack>

            {/* Forms Table */}
            <Paper elevation={0} className="pcontainer">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell scope="row">
                      <Typography
                        variant="body1"
                        fontWeight="500"
                        fontSize="16px"
                      >
                        Package Title
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Stack spacing={2}>
                        <Typography
                          variant="h4"
                          fontSize="16px"
                          fontWeight="500"
                        >
                          Basic
                        </Typography>
                        <TextField
                          name="basicTitle"
                          placeholder="Basic title"
                          helperText="30 characters max"
                          value={basicTitle || ''}
                          error={!!formErrors.basicTitle}
                          onChange={handleChange}
                          required
                          inputProps={{ maxLength: 30 }}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: '2px solid #E4E5E7',
                            },
                          }}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack spacing={2}>
                        <Typography
                          variant="h4"
                          fontSize="16px"
                          fontWeight="500"
                        >
                          Standard
                        </Typography>
                        <TextField
                          name="standardTitle"
                          placeholder="Standard title"
                          helperText="30 characters max"
                          value={standardTitle || ''}
                          error={!!formErrors.standardTitle}
                          onChange={handleChange}
                          required
                          inputProps={{ maxLength: 30 }}
                          disabled={!isCompletePackage}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: '2px solid #E4E5E7',
                            },
                          }}
                        />
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Stack spacing={2}>
                        <Typography
                          variant="h4"
                          fontSize="16px"
                          fontWeight="500"
                        >
                          Premium
                        </Typography>
                        <TextField
                          name="premiumTitle"
                          placeholder="Premium title"
                          helperText="30 characters max"
                          value={premiumTitle || ''}
                          error={!!formErrors.premiumTitle}
                          onChange={handleChange}
                          required
                          inputProps={{ maxLength: 30 }}
                          disabled={!isCompletePackage}
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: '2px solid #E4E5E7',
                            },
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="500"
                        fontSize="16px"
                      >
                        Package Descirption
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        id="basicDescription"
                        name="basicDescription"
                        placeholder="Basic Description"
                        helperText="90 characters max"
                        value={basicDescription || ''}
                        error={!!formErrors.basicDescription}
                        onChange={handleChange}
                        required
                        inputProps={{ maxLength: 90 }}
                        multiline
                        rows={2}
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '2px solid #E4E5E7',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        id="standardDescription"
                        name="standardDescription"
                        disabled={!isCompletePackage}
                        placeholder="Standard Description"
                        helperText="90 characters max"
                        value={standardDescription || ''}
                        error={!!formErrors.standardDescription}
                        onChange={handleChange}
                        inputProps={{ maxLength: 90 }}
                        multiline
                        rows={2}
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '2px solid #E4E5E7',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        id="premiumDescription"
                        name="premiumDescription"
                        disabled={!isCompletePackage}
                        placeholder="Premium Description"
                        helperText="90 characters max"
                        value={premiumDescription || ''}
                        error={!!formErrors.premiumDescription}
                        onChange={handleChange}
                        inputProps={{ maxLength: 90 }}
                        multiline
                        rows={2}
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '2px solid #E4E5E7',
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="500"
                        fontSize="16px"
                      >
                        Delivery Days
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="basicDelivery"
                        placeholder="0"
                        value={basicDelivery || ''}
                        error={!!formErrors.basicDelivery}
                        onChange={handleChange}
                        helperText={formErrors.basicDelivery}
                        required
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '2px solid #E4E5E7',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="standardDelivery"
                        value={standardDelivery || ''}
                        error={!!formErrors.standardDelivery}
                        onChange={handleChange}
                        helperText={formErrors.standardDelivery}
                        disabled={!isCompletePackage}
                        placeholder="0"
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '2px solid #E4E5E7',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="premiumDelivery"
                        value={premiumDelivery || ''}
                        error={!!formErrors.premiumDelivery}
                        onChange={handleChange}
                        helperText={formErrors.premiumDelivery}
                        disabled={!isCompletePackage}
                        placeholder="0"
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '2px solid #E4E5E7',
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="500"
                        fontSize="16px"
                      >
                        Revisions
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="basicRevisions"
                        placeholder="0"
                        value={basicRevisions || ''}
                        error={!!formErrors.basicRevisions}
                        onChange={handleChange}
                        helperText={formErrors.basicRevisions}
                        required
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '2px solid #E4E5E7',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="standardRevisions"
                        value={standardRevisions || ''}
                        error={!!formErrors.standardRevisions}
                        onChange={handleChange}
                        helperText={formErrors.standardRevisions}
                        disabled={!isCompletePackage}
                        placeholder="0"
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '2px solid #E4E5E7',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="premiumRevisions"
                        value={premiumRevisions || ''}
                        error={!!formErrors.premiumRevisions}
                        onChange={handleChange}
                        helperText={formErrors.premiumRevisions}
                        disabled={!isCompletePackage}
                        placeholder="0"
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '2px solid #E4E5E7',
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  {subcategory && subcategoryForm().element}
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="500"
                        fontSize="16px"
                      >
                        Pricing
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="basicPrice"
                        placeholder="0"
                        value={basicPrice || ''}
                        error={!!formErrors.basicPrice}
                        onChange={handleChange}
                        helperText={formErrors.basicPrice}
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '2px solid #E4E5E7',
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="standardPrice"
                        placeholder="0"
                        value={standardPrice || ''}
                        error={!!formErrors.standardPrice}
                        onChange={handleChange}
                        helperText={formErrors.standardPrice}
                        disabled={!isCompletePackage}
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '2px solid #E4E5E7',
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="premiumPrice"
                        placeholder="0"
                        value={premiumPrice || ''}
                        error={!!formErrors.premiumPrice}
                        onChange={handleChange}
                        helperText={formErrors.premiumPrice}
                        disabled={!isCompletePackage}
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            border: '2px solid #E4E5E7',
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>

            {/* Payment Method */}
            <Stack
              direction="column"
              spacing={1}
              alignItems="flex-start"
              sx={{ mt: '24px' }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <ErrorOutline
                  sx={{ color: 'primary.main', fontSize: '32px' }}
                />
                <Typography variant="body1" fontWeight="400" fontSize="16px">
                  You accept payment in ETH, DAI, USDT +26...
                </Typography>
              </Stack>
              <Button sx={{ fontSize: '18px' }}>Manage cryptocurrencies</Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item sx={{ mt: { xs: '24px', lg: 0 } }}>
          <Card
            variant="outlined"
            sx={{
              px: '32px',
              py: '24px',
              maxWidth: { xs: '100%', md: '310px' },
            }}
          >
            <CardHeader
              title="Pricing setup"
              sx={{
                p: 0,
                '& .MuiTypography-root': {
                  fontSize: '20px',
                  fontWeight: 'medium',
                },
              }}
            />
            <CardContent
              sx={{
                p: 0,
                pb: 0,
                '&:last-child': {
                  pb: 0,
                },
              }}
            >
              <List
                sx={{
                  listStyleType: 'disc',
                  pl: '16px',
                  '& .MuiListItem-root': {
                    display: 'list-item',
                  },
                }}
              >
                <ListItem disablePadding>
                  <ListItemText
                    sx={{ color: 'primary.main', fontSize: '14px' }}
                  >
                    Detail the scope and pricing of your project so clients
                    understand what they will receive.
                  </ListItemText>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText
                    sx={{ color: 'primary.main', fontSize: '14px' }}
                  >
                    Build 1 or 3 tiers to provide different pricing options for
                    your clients.
                  </ListItemText>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Buttons */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          pt: 2,
          justifyContent: 'flex-end',
        }}
      >
        <Button color="inherit" onClick={handleBackAndScroll} sx={{ mr: 1 }}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={isValid ? handleNextAndScroll : null}
          disabled={!isValid}
        >
          Save & Continue
        </Button>
      </Box>
    </Stack>
  );
}
