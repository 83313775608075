import { Typography, Stack, Button, Grid } from '@mui/material';
import CallMadeIcon from '@mui/icons-material/CallMade';

import NewsCard from '../components/NewsCard';

export const Section7 = () => {
  return (
    <Grid
      container
      direction={{ xs: 'row', sm: 'row' }}
      sx={{ px: { sm: 0, lg: 8 }, mt: '150px' }}
      justifyContent="space-between"
    >
      <Grid item lg={3}>
        <Stack spacing={1}>
          <Typography
            variant="h2"
            color="text.primary"
            sx={{
              fontSize: '44px',
              fontWeight: '600',
              lineHeight: '48px',
              pt: '36px',
            }}
          >
            Latest News
          </Typography>
          <Typography variant="body1">
            Check more posts in our blog for featured news and advertising
            insights
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ fontSize: '14px', py: '12px', px: '24px', width: '150px' }}
            endIcon={<CallMadeIcon />}
          >
            Go To Blog
          </Button>
        </Stack>
      </Grid>
      <Grid item sx={{ mt: { xs: '24px' } }}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
          <NewsCard />
          <NewsCard />
        </Stack>
      </Grid>
    </Grid>
  );
};
