const countries = [
  'United States',
  'United Kingdom',
  'Canada',
  'Australia',
  'Germany',
  'France',
  'Italy',
  'Spain',
  'Brazil',
  'India',
  'China',
  'Japan',
  'South Korea',
  'Mexico',
  'Argentina',
  'Russia',
  'South Africa',
  'Egypt',
  'Nigeria',
  'Kenya',
];

export const fulltimeJobs = [
  {
    id: generateRandomId(),
    userName: 'JohnDoe',
    levelStatus: 'Level 1 Seller',
    title: 'I will develop a mobile app for iOS and Android platforms',
    rating: '4.0',
    ratingCount: '1,234',
    price: 150,
    category: 'Web, Mobile & Software Dev',
    date: '6/5/2023',
    imgUrl:
      'https://fakeimg.pl/364x231/ebebf3/9292D4?text=Placeholder&font=bebas',
    online: Math.random() < 0.5,
    location: countries[Math.floor(Math.random() * countries.length)],
  },
  {
    id: generateRandomId(),
    userName: 'JaneSmith',
    levelStatus: 'Top Rated Seller',
    title: 'I will create awesome professional website using WordPress',
    rating: '5.0',
    ratingCount: '3,567',
    price: 100,
    category: 'Web, Mobile & Software Dev',
    date: '6/4/2023',
    imgUrl:
      'https://fakeimg.pl/364x231/ebebf3/9292D4?text=Placeholder&font=bebas',
    online: Math.random() < 0.5,
    location: countries[Math.floor(Math.random() * countries.length)],
  },
  {
    id: generateRandomId(),
    userName: 'SamJones',
    levelStatus: 'Level 2 Seller',
    title: 'I will provide virtual assistance for your administrative tasks',
    rating: '3.5',
    ratingCount: '1,678',
    price: 20,
    category: 'Admin Support',
    date: '6/3/2023',
    imgUrl:
      'https://fakeimg.pl/364x231/ebebf3/9292D4?text=Placeholder&font=bebas',
    online: Math.random() < 0.5,
    location: countries[Math.floor(Math.random() * countries.length)],
  },
  {
    id: generateRandomId(),
    userName: 'EmilyDavis',
    levelStatus: 'Level 1 Seller',
    title: 'I will design a unique and eye-catching logo for your brand',
    rating: '4.0',
    ratingCount: '2,890',
    price: 50,
    category: 'Design & Creative',
    date: '6/2/2023',
    imgUrl:
      'https://fakeimg.pl/364x231/ebebf3/9292D4?text=Placeholder&font=bebas',
    online: Math.random() < 0.5,
    location: countries[Math.floor(Math.random() * countries.length)],
  },
  {
    id: generateRandomId(),
    userName: 'SarahMiller',
    levelStatus: 'Top Rated Seller',
    title: 'I will write awesome engaging and SEO-friendly blog articles',
    rating: '4.0',
    ratingCount: '800',
    price: 30,
    category: 'Writing & Translation',
    date: '6/1/2023',
    imgUrl:
      'https://fakeimg.pl/364x231/ebebf3/9292D4?text=Placeholder&font=bebas',
    online: Math.random() < 0.5,
    location: countries[Math.floor(Math.random() * countries.length)],
  },
  {
    id: generateRandomId(),
    userName: 'MarkWilson',
    levelStatus: 'Level 1 Seller',
    title: 'I will create a social media marketing strategy for your business',
    rating: '3.5',
    ratingCount: '1,045',
    price: 80,
    category: 'Sales & Marketing',
    date: '5/31/2023',
    imgUrl:
      'https://fakeimg.pl/364x231/ebebf3/9292D4?text=Placeholder&font=bebas',
    online: Math.random() < 0.5,
    location: countries[Math.floor(Math.random() * countries.length)],
  },
  {
    id: generateRandomId(),
    userName: 'AmyJohnson',
    levelStatus: 'Top Rated Seller',
    title: 'I will provide professional voice-over services for your project',
    rating: '4.0',
    ratingCount: '2,345',
    price: 40,
    category: 'Video & Animation',
    date: '5/30/2023',
    imgUrl:
      'https://fakeimg.pl/364x231/ebebf3/9292D4?text=Placeholder&font=bebas',
    online: Math.random() < 0.5,
    location: countries[Math.floor(Math.random() * countries.length)],
  },
  {
    id: generateRandomId(),
    userName: 'David Brown',
    levelStatus: 'Level 2 Seller',
    title: 'I will fix bugs and optimize your website for better performance',
    rating: '3.0',
    ratingCount: '650',
    price: 90,
    category: 'Web, Mobile & Software Dev',
    date: '5/29/2023',
    imgUrl:
      'https://fakeimg.pl/364x231/ebebf3/9292D4?text=Placeholder&font=bebas',
    online: Math.random() < 0.5,
    location: countries[Math.floor(Math.random() * countries.length)],
  },
  {
    id: generateRandomId(),
    userName: 'Marouane Pablo',
    levelStatus: 'Level 2 Seller',
    title: 'I will fix bugs and optimize your website for better performance',
    rating: '5.0',
    ratingCount: '650',
    price: 500,
    category: 'Web, Mobile & Software Dev',
    date: '6/29/2023',
    imgUrl:
      'https://fakeimg.pl/364x231/ebebf3/9292D4?text=Placeholder&font=bebas',
    online: Math.random() < 0.5,
    location: countries[Math.floor(Math.random() * countries.length)],
  },
  {
    id: generateRandomId(),
    userName: 'Zayd Pablo',
    levelStatus: 'Level 1 Seller',
    title: 'I will fix bugs and optimize your website for better performance',
    rating: '5.0',
    ratingCount: '650',
    price: 300,
    category: 'Web, Mobile & Software Dev',
    date: '6/29/2023',
    imgUrl:
      'https://fakeimg.pl/364x231/ebebf3/9292D4?text=Placeholder&font=bebas',
    online: Math.random() < 0.5,
    location: countries[Math.floor(Math.random() * countries.length)],
  },
  {
    id: generateRandomId(),
    userName: 'Zayd Pablo',
    levelStatus: 'Level 1 Seller',
    title: 'I will fix bugs and optimize your website for better performance',
    rating: '5.0',
    ratingCount: '650',
    price: 300,
    category: 'Web, Mobile & Software Dev',
    date: '6/19/2023',
    imgUrl:
      'https://fakeimg.pl/364x231/ebebf3/9292D4?text=Placeholder&font=bebas',
    online: Math.random() < 0.5,
    location: countries[Math.floor(Math.random() * countries.length)],
  },
  {
    id: generateRandomId(),
    userName: 'Annie Johnson',
    levelStatus: 'Level 2 Seller',
    title: 'I will create awesome professional logo design for your business',
    rating: '4.8',
    ratingCount: '430',
    price: 250,
    category: 'Design & Creative',
    date: '6/10/2023',
    imgUrl:
      'https://fakeimg.pl/364x231/ebebf3/9292D4?text=Placeholder&font=bebas',
    online: Math.random() < 0.5,
    location: countries[Math.floor(Math.random() * countries.length)],
  },
];

function generateRandomId() {
  const randomId = Math.floor(Math.random() * 900000) + 100000;
  return randomId.toString();
}
